/*--------------------------------------------------
	[Footer]
----------------------------------------------------*/
.footer-v8 .footer {
	background: #151515;
	padding: 60px 0 40px;
}

.footer-v8 .footer img.footer-logo {
	margin-bottom: 30px;
}

.footer-v8 .footer h2 {
	color: #eee;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	margin: 2px 0 36px;
	position: relative;
	text-transform: uppercase;
}

.footer-v8 .footer h2:after {
	left: 0;
	width: 30px;
	height: 1px;
	content: " ";
	bottom: -10px;
	background: #eee;
	position: absolute;
}

.footer-v8 .footer p {
	color: #eee;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
}

.footer-v8 .footer strong {
	color: #eee;
}

.footer-v8 .footer hr {
	margin: 15px 0;
	border-color: #222;
}

/*Footer Column One*/
.footer-v8 .footer .column-one span {
	color: #eee;
	display: block;
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 5px;
}

.footer-v8 .footer .column-one a {
	color: #bbb;
	font-size: 13px;
}

.footer-v8 .footer .column-one a:hover {
	text-decoration: none;
}

/*Footer Tags v4*/
.footer-v8 .footer .tags-v4 li {
	line-height: 30px;
}

.footer-v8 .footer .tags-v4 a {
	color: #eee;
	border-color: #eee;
}

/*Footer Lists*/
.footer-v8 .footer .footer-lists {
	padding-left: 0;
	list-style: none;
}

.footer-v8 .footer .footer-lists li {
	display: block;
	font-size: 13px;
	margin-bottom: 5px;
}

.footer-v8 .footer .footer-lists li i {
	color: #bbb;
	margin-right: 3px;
	vertical-align: middle;
}

.footer-v8 .footer .footer-lists a {
	color: #bbb;
}

.footer-v8 .footer .footer-lists li a:hover {
	text-decoration: none;
}

/*Footer Latest News*/
.footer-v8 .footer .latest-news {
	overflow: hidden;
}

.footer-v8 .footer .latest-news img {
	float: left;
	width: 60px;
	height: auto;
	margin: 4px 10px 0 0;
}

.footer-v8 .footer .latest-news h3 {
	margin: 0 0 3px;
	font-size: 14px;
	line-height: 20px;
}

.footer-v8 .footer .latest-news h3 a {
	color: #fff;
}

.footer-v8 .footer .latest-news h3 a:hover {
	text-decoration: none;
}

.footer-v8 .footer .latest-news p {
	font-size: 12px;
	margin-bottom: 1px;
}

/*Footer Subscribe Form*/
.footer-v8 .footer .form-control {
	color: #bbb;
	font-size: 13px;
	font-weight: 400;
	min-height: 30px;
	box-shadow: none;
	border-color: #222;
	padding-right: 15px;
	background: rgba(0,0,0,0.2);
	border-top-left-radius: 30px !important;
	border-bottom-left-radius: 30px !important;
}

.footer-v8 .footer .form-control::-moz-placeholder {
	color: #bbb;
}
.footer-v8 .footer .form-control:-ms-input-placeholder {
	color: #bbb;
}
.footer-v8 .footer .form-control::-webkit-input-placeholder {
	color: #bbb;
}

.footer-v8 .footer .input-group-btn .input-btn {
	color: #fff;
	border: none;
	font-size: 13px;
	margin-left: -1px;
	padding: 6px 10px 5px;
	border-top-right-radius: 30px !important;
	border-bottom-right-radius: 30px !important;
}

/*Footer Social Icons*/
.footer-v8 .footer .social-icon-list {
	padding-left: 0;
	list-style: none;
}

.footer-v8 .footer .social-icon-list li {
	padding: 0 3px;
	display: inline-block;
}

.footer-v8 .footer .social-icon-list li i {
	color: #fff;
	width: 30px;
	height: 30px;
	padding: 7px;
	font-size: 14px;
	text-align: center;
	line-height: normal;
	display: inline-block;
	border: 1px solid #fff;
}

.footer-v8 .footer .social-icon-list li i:hover {
	-webkit-transition: all 0.35s, -webkit-transform 0.35s;
	transition: all 0.35s, transform 0.35s;
}

/*Footer Copyright
------------------------------------*/
.footer-v8 .copyright {
	padding: 15px 0;
	background: #000;
}

.footer-v8 .copyright .terms-menu {
	margin: 5px 0 0;
}

.footer-v8 .copyright .terms-menu li {
	color: #bbb;
	font-size: 12px;
	line-height: 10px;
	margin-right: 2px;
	padding-left: 10px;
	padding-left: 10px;
	border-left: 1px solid #bbb;
}

.footer-v8 .copyright .terms-menu li:first-child {
	border-left: none;
	padding: 0 7px 0 0;
}

.footer-v8 .copyright .terms-menu li a {
	color: #bbb;
}

.footer-v8 .copyright .terms-menu li a:hover {
	color: #fff;
	text-decoration: none;
}
