/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.9
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/

/*--------------------------------------------------
[Import Global Compulsory CSS Files]
----------------------------------------------------*/

@import url(app.css);
@import url(blocks.css);

@media (min-width: 768px) {
	.g-blur { overflow: hidden; }
	.g-blur .wrapper,
	.g-blur .outside-more-articles {
		filter: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg"><filter id="blur"><feGaussianBlur stdDeviation="7" /></filter></svg>#blur');
		filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='7');
		-webkit-filter: blur(7px);
		-moz-filter:blur (7px);
		-ms-filter: blur(7px);
		filter: blur(7px);
	}
	body.header-fixed-space-v2.g-blur { padding-top: 0; }
}

/*--------------------------------------------------
[Blog Styles]
----------------------------------------------------*/
body {
	color: #333;
	font: 13px/1.6 'Roboto Slab', sans-serif;
	text-rendering: optimizelegibility;
}

body.header-fixed-space-v2 {
	padding-top: 135px;
}

@media (max-width: 991px) {
	body.header-fixed-space-v2 {
		padding-top: 0;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Roboto Slab', sans-serif;
}

/*--------------------------------------------------
[Boxed Layout]
----------------------------------------------------*/

.wrapper {
	background: #fff;
}

/

/*--------------------------------------------------
[Masonry Blog]
----------------------------------------------------*/

/*Masonry Box
------------------------------------*/
.masonry-box {
	margin: 0 -15px;
	padding: 0 auto;
}

.masonry-box .masonry-box-in {
	float: left;
	padding: 0 15px;
	margin-bottom: 50px;
}

.masonry-box .masonry-box-in.col-2 {
	width: 50%;
}

.masonry-box .masonry-box-in.col-3 {
	width: 33.33333%;
}

.masonry-box .masonry-box-in.col-4 {
	width: 25%;
}

@media (max-width: 650px) {
	.masonry-box .masonry-box-in.col-2,
	.masonry-box .masonry-box-in.col-3,
	.masonry-box .masonry-box-in.col-4 {
		width: 100%;
	}
}

@media (min-width: 651px) and (max-width: 991px) {
	.masonry-box .masonry-box-in.col-3,
	.masonry-box .masonry-box-in.col-4 {
		width: 50%;
	}
}

@media (min-width: 651px) and (max-width: 991px) {
	.masonry-box .masonry-box-in.col-4 {
		width: 50%;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.masonry-box .masonry-box-in.col-4 {
		width: 33.33333%;
	}
}

/*--------------------------------------------------
[Blog Grid]
----------------------------------------------------*/

.blog-grid img {
	margin-bottom: 15px;
}

.blog-grid h3 {
	font-size: 22px;
	margin: 0 0 10px;
	line-height: 1.4;
}

.blog-grid h2.blog-grid-title-lg {
	font-size: 28px;
	line-height: 1.4;
	margin: 0 0 10px;
}

.blog-grid h3.blog-grid-title-sm {
	font-size: 15px;
	line-height: 1.4;
}

.blog-grid h3 a {
	color: #333;
}

.blog-grid p {
	margin-bottom: 15px;
}

.blog-grid-inner {
	padding: 20px;
	background: #fff;
	margin: -70px 40px 0;
	position: relative;
}

/*Blog Grid Gradient*/
.blog-grid .blog-grid-grad {
	position: relative;
}

.blog-grid .blog-grid-grad i {
	top: 10px;
	left: 10px;
	z-index: 1;
	color: #fff;
	width: 30px;
	height: 30px;
	font-size: 18px;
	line-height: 30px;
	text-align: center;
	position: absolute;
	display: inline-block;
	background: rgba(0,0,0,0.5);
}

/*Blog Grid Info*/
.blog-grid .blog-grid-info {
	padding-left: 0;
	list-style: none;
}

.blog-grid .blog-grid-info li {
	color: #888;
	padding: 0 2px;
	font-size: 12px;
	display: inline-block;
}

.blog-grid .blog-grid-info li:before {
	content: '/';
	font-size: 12px;
	line-height: 1.4;
	margin-right: 9px;
}

.blog-grid .blog-grid-info li:first-child:before {
	content: " ";
	margin-right: 0;
}

.blog-grid .blog-grid-info li a {
	color: #888;
}

.blog-grid .blog-grid-info li a:hover {
	text-decoration: none;
}

/*Read More Link*/
.blog-grid a.r-more {
	font-size: 13px;
	font-weight: 400;
	font-style: italic;
	display: inline-block;
}

.blog-grid a.r-more:hover {
	text-decoration: none;
}


/*--------------------------------------------------
[Blog Thumb]
----------------------------------------------------*/

.blog-thumb {
	overflow: hidden;
}

/*Blog Grid Hover*/
.blog-thumb .blog-thumb-hover {
	float: left;
	position: relative;
	margin-right: 15px;
}

.blog-thumb .blog-thumb-hover:after {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: " ";
	position: absolute;
	-webkit-transition: all 0.35s, -webkit-transform 0.35s;
	transition: all 0.35s, transform 0.35s;
}

.blog-thumb .blog-thumb-hover img {
	width: 120px;
	height: auto;
}

.blog-thumb .blog-thumb-hover img.video-play-btn {
	width: 30px;
	height: 30px;
}

/*Icon Hover Gradient*/
.blog-thumb .blog-thumb-hover .hover-grad {
	left: 0;
	top: 50%;
	z-index: 1;
	opacity: 0;
	width: 100%;
	color: #fff;
	font-size: 20px;
	margin-top: -14px;
	text-align: center;
	position: absolute;
	display: inline-block;
	-webkit-transition: all 0.65s, -webkit-transform 0.65s;
	transition: all 0.65s, transform 0.65s;
}

.blog-thumb .blog-thumb-hover:hover .hover-grad {
	opacity: 1;
	-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
	transition: opacity 0.55s, transform 0.55s;
}

/*Blog Thumb Description*/
.blog-thumb .blog-thumb-desc {
	overflow: hidden;
}

.blog-thumb .blog-thumb-desc h3 {
	font-size: 14px;
	margin: 0 0 5px;
	font-weight: 400;
	line-height: 1.4;
}

.blog-thumb .blog-thumb-desc h3 a {
	color: #555;
}

/*Blog Grid Info*/
.blog-thumb .blog-thumb-info {
	padding-left: 0;
	list-style: none;
}

.blog-thumb .blog-thumb-info li {
	color: #888;
	padding: 0 2px;
	font-size: 12px;
	display: inline-block;
}

.blog-thumb .blog-thumb-info li:before {
	content: '/';
	font-size: 12px;
	line-height: 1.4;
	margin-right: 9px;
}

.blog-thumb .blog-thumb-info li:first-child:before {
	content: " ";
	margin-right: 0;
}

.blog-thumb .blog-thumb-info li a {
	color: #888;
}

.blog-thumb .blog-thumb-info li a:hover {
	color: #e74c3c;
}


/*Blog Thumb Circle
------------------------------------*/
.blog-thumb.blog-thumb-circle .blog-thumb-hover:after {
	border-radius: 50% !important;
}

.blog-thumb.blog-thumb-circle .blog-thumb-hover img {
	width: 50px;
	height: 50px;
}

.blog-thumb.blog-thumb-circle .blog-thumb-hover .hover-grad {
	font-size: 15px;
	margin-top: -10px;
}

/*--------------------------------------------------
[Blog Thumb v3]
----------------------------------------------------*/

.blog-thumb-v3 h3 {
	font-size: 13px;
	margin: 0 0 5px;
	font-weight: 400;
	line-height: 1.4;
}

.blog-thumb-v3 h3 a {
	color: #555;
}

.blog-thumb-v3 small,
.blog-thumb-v3 small a {
	color: #999;
}

.blog-thumb-v3 small a:hover {
	color: #555;
}

.form-control {
	height: auto;
}
