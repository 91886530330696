/*/////////
/// Customizations to @media queries
//////////*/

/*//////////
/// Colors - AllTrips Brand
/// Header
//////////*/




/*//////////
/// Header
//////////*/

/*Desktop*/
@media (max-width: 1199px) {
  .navbar-brand {
    width: 300px;
  }
  .logo h4 {
    font-size: 1.8rem;
  }
  .header-fixed .header-v8.header-fixed-shrink .logo h4 {
    font-size: 1.8rem;
  }
  .header-v8 .navbar-nav > li > a {
    padding: 10px 3px;
  }
  .btn-mainnav {
    font-size: 12px;
  }
}
/* Desktop - Medium Resolution */
@media (max-width: 1367px) {
  .interactive-slider-v2.img-v4 {
    min-height: 560px;
  }
}
/*Desktop - Low Resolution*/
@media (max-width: 991px) {
  .logo h4 {
    font-size: 1.4rem;
  }
	.header-v8 .navbar-nav .open .dropdown-menu > li > a {
	  color: #ffffff;
	}
	.header-v8 .navbar-nav > li a {
	  border-bottom: none;
	  border-top: none; 
	}
	.btn-mainnav {
		display: block;
	}
  body.header-fixed-space-v2 {
    padding-top: 0;
  }
  .interactive-slider-v2.img-v4 {
    min-height: 480px;
  }
}
/*iPad*/
@media (max-width: 767px) {
  .blog-topbar {
    display: none;
  }
  body.header-fixed-space-v2 {
    padding-top: 0;
  }
  .home h2 {
    font-size: 2.2rem;
  }
}
/*iPhone 6*/
@media (max-width: 375px) {
  .navbar-brand {
    width: 260px;
  }
  body.header-fixed-space-v2 {
    padding-top: 0;
  }
  .home h1 {
    font-size: 3rem;
  }
  .home h2 {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
	.header-v8 .dropdown-menu > li > a {
    	color: #1b3043;
	    font-size: 15px;
	    font-weight: 400;
	    /*padding: 6px 25px;*/
	}
}
@media (min-width: 768px) {
	.navbar {
    border-radius: 0;
	}
}
@media (min-width: 375px) {
  .navbar-toggle {
    display: none;
  }
}




