/* Google Fonts  */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&family=Roboto+Slab:wght@400;500;700&display=swap");

html {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  line-height: 1.6;
  font-weight: 400;
  margin-top: 0;
}

body .article_page {
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  line-height: 1.6;
  font-weight: 400;
  margin-top: 0;
}

.article_page p {
  line-height: 1.6 !important;
}

#site-content h1,
#site-content h1>span,
#site-content h1>a,
#site-content h1>a>span,
#site-content h2,
#site-content h2>span,
#site-content h2>a,
#site-content h2>a>span,
#site-content h3,
#site-content h3>span,
#site-content h3>a,
#site-content h3>a>span,
#site-content h4,
#site-content h4>span,
#site-content h4>a,
#site-content h4>a>span,
#site-content h5,
#site-content h5>span,
#site-content h5>a,
#site-content h5>a>span,
#site-content h6,
#site-content h6>span,
#site-content h6>a,
#site-content h6>a>span {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  line-height: 1.4;
}

#site-content a,
#site-content abbr,
#site-content acronym,
#site-content address,
#site-content applet,
#site-content article,
#site-content aside,
#site-content audio,
#site-content b,
#site-content big,
#site-content blockquote,
#site-content canvas,
#site-content caption,
#site-content center,
#site-content cite,
#site-content code,
#site-content del,
#site-content details,
#site-content dfn,
#site-content div,
#site-content em,
#site-content embed,
#site-content fieldset,
#site-content figcaption,
#site-content figure,
#site-content footer,
#site-content form,
#site-content header,
#site-content hgroup,
#site-content i,
#site-content iframe,
#site-content img,
#site-content input,
#site-content ins,
#site-content kbd,
#site-content label,
#site-content legend,
#site-content li,
#site-content mark,
#site-content menu,
#site-content nav,
#site-content object,
#site-content ol,
#site-content output,
#site-content p,
#site-content pre,
#site-content q,
#site-content ruby,
#site-content s,
#site-content samp,
#site-content section,
#site-content small,
#site-content span,
#site-content strike,
#site-content summary,
#site-content table,
#site-content tbody,
#site-content td,
#site-content textarea,
#site-content tfoot,
#site-content th,
#site-content thead,
#site-content time,
#site-content tr,
#site-content tt,
#site-content u,
#site-content ul,
#site-content var,
#site-content video {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  line-height: 1.4;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0;
}

#site-content h1,
#site-content h2,
#site-content h3,
#site-content h4,
#site-content h5,
#site-content h6 {
  margin-top: 0;
  margin-bottom: 15px;
}

#site-content h1,
#site-content h1>span,
#site-content h1>a,
#site-content h1>a>span {
  font-weight: 700;
  font-size: 3.138428376721rem;
  font-size: clamp(2.0273rem, 1.7576rem + 1.1507vw, 3.1384rem);
}

#site-content h2,
#site-content h2>span,
#site-content h2>a,
#site-content h2>a>span {
  font-weight: 700;
  font-size: 2.5937424601rem;
  font-size: clamp(1.802rem, 1.6099rem + 0.8199vw, 2.5937rem);
}

#site-content h3,
#site-content h3>span,
#site-content h3>a,
#site-content h3>a>span {
  font-weight: 700;
  font-size: 2.14358881rem;
  font-size: clamp(1.6018rem, 1.4703rem + 0.5611vw, 2.1436rem);
}

#site-content h4,
#site-content h4>span,
#site-content h4>a,
#site-content h4>a>span {
  font-weight: 700;
  font-size: 1.771561rem;
  font-size: clamp(1.4238rem, 1.3394rem + 0.3601vw, 1.7716rem);
}

#site-content h5,
#site-content h5>span,
#site-content h5>a,
#site-content h5>a>span {
  font-weight: 700;
  font-size: 1.4641rem;
  font-size: clamp(1.2656rem, 1.2175rem + 0.2055vw, 1.4641rem);
}

#site-content h6,
#site-content h6>span,
#site-content h6>a,
#site-content h6>a>span {
  font-weight: 700;
  font-size: 1.21rem;
  font-size: clamp(1.125rem, 1.1044rem + 0.088vw, 1.21rem);
}

#site-content li,
#site-content li>p,
#site-content li>p>span,
#site-content li>a,
#site-content li>a>span {
  font-size: 0.929rem;
  font-weight: 600;
  line-height: 1.714rem;
}

#site-content a.site-button,
#site-content a.site-button>span,
#site-content #contactForm input[type="submit"] {
  font-size: 1.143rem;
  font-weight: 700;
  line-height: 1.5rem;
  border: 0;
}

#site-content img {
  width: 100%;
  height: auto;
  display: block;
}

#site-content ul {
  padding-left: 15px;
}

#site-content .page_grid,
#site-content .banner_search,
#site-content .stacked_grid_wrapper,
#site-content .bg_cta {
  max-width: 1245px;
  margin-left: auto;
  margin-right: auto;
}

#site-content .page_muti_columns_grid {
  max-width: 1410px;
  margin-left: auto;
  margin-right: auto;
}

#site-content .bg_cta_content {
  max-width: 420px;
  margin-right: auto;
}

#site-content #home_iternity_one .page_grid,
#site-content #home_iternity_two .page_grid,
#site-content #home_iternity_three .page_grid,
#site-content #yellowstone_iternity .page_grid,
#site-content #jenny_iternity .page_grid,
#site-content #jenny_lake_ad .page_grid,
#site-content #yellowstone_featured_activities .page_muti_columns_grid,
#site-content #summer_yellowstone_activities .page_muti_columns_grid {
  max-width: 1193px;
}

#site-content a.site-button {
  position: relative;
  overflow: hidden;
}

#site-content a.site-button:hover span {
  color: #fff;
}

#site-content a.site-button>span {
  z-index: 1;
  position: relative;
  -webkit-transition: 500ms ease all;
  -o-transition: 500ms ease all;
  transition: 500ms ease all;
}

#site-content a.site-button,
#site-content a.site-button:visited,
#site-content a.site-button:active,
#site-content #contactForm input[type="submit"],
#site-content #contactForm input[type="submit"]:visited,
#site-content #contactForm input[type="submit"]:active {
  background: #ffa41e;
  text-decoration: none;
  color: #0c0c0c;
  padding: 15px 10px;
  display: inline-block;
  -webkit-transition: 500ms ease all;
  -o-transition: 500ms ease all;
  transition: 500ms ease all;
  cursor: pointer;
}

#site-content a.site-button:hover:before {
  left: 0;
  opacity: 1;
  z-index: 1;
}

#site-content a.site-button:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #a4bd00;
  position: absolute;
  top: 0;
  left: -200%;
  opacity: 0;
  -webkit-transition: 500ms ease all;
  -o-transition: 500ms ease all;
  transition: 500ms ease all;
  z-index: -1;
}

#site-content a.site-button:hover,
#site-content a.site-button:focus,
#site-content #contactForm input[type="submit"]:hover,
#site-content #contactForm input[type="submit"]:focus,
#site-content a.site-button.site-button-transparent:hover,
#site-content a.site-button.site-button-transparent:focus {
  outline: none;
}

#site-content a.site-button.site-button-transparent {
  border: 1px solid #a4bd00;
  background: #1c3d56;
  color: #a4bd00;
}

#site-content .page_banner {
  position: relative;
  margin-bottom: 30px;
}

#site-content #home-banner:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: -o-linear-gradient(bottom, #000 0%, rgba(0, 0, 0, 0) 50%);
  background: -webkit-gradient(linear, left bottom, left top, from(#000), color-stop(50%, rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 50%);
}

#site-content .page_banner_caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

#site-content .page_banner_caption h1 {
  text-align: center;
  color: #fff;
  margin-bottom: 11px;
}

#site-content .page_banner_caption h4 {
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

#site-content .banner_search_wrapper {
  background: #a4bd00;
}

#site-content .banner_search ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#site-content .banner_search ul li {
  list-style: none;
}

#site-content .banner_search ul li p {
  margin-bottom: 0;
  color: #fff;
  font-size: 1.21rem;
  font-size: clamp(1.125rem, 1.1044rem + 0.088vw, 1.21rem);
  font-weight: 600;
}

#site-content .banner_search select,
#site-content .banner_search .ui-selectmenu-button {
  padding: 8px 30px 8px 16px;
  border-radius: 2px;
  background: #869a00;
  color: #fff;
  font-size: 1.21rem;
  font-size: clamp(1.125rem, 1.1044rem + 0.088vw, 1.21rem);
  font-weight: 600;
  outline: none;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 0 center;
  background-size: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z' fill='%23ffffff'/%3E%3C/svg%3E");
  width: auto;
}

#site-content .banner_search .ui-selectmenu-button span.ui-selectmenu-text {
  font-size: 1.21rem;
  font-size: clamp(1.125rem, 1.1044rem + 0.088vw, 1.21rem);
  font-weight: 600;
  line-height: 1.3;
}

#site-content .banner_search .ui-selectmenu-button span.ui-selectmenu-icon.ui-icon {
  display: none;
}

#site-content .banner_search select::-ms-expand {
  display: none;
  /* Hide the default arrow in IE */
}

#site-content .banner_search {
  padding-top: 6px;
  padding-bottom: 6px;
}

#site-content .banner_search ul li:last-child {
  margin-right: 0;
}

#site-content .banner_search ul li {
  list-style: none;
  margin-right: 16px;
}

#site-content .page_grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

#site-content #home_intro,
#site-content #summer_yellowstone_intro {
  padding-top: 60px;
  padding-bottom: 15px;
}

#site-content #home_intro {
  background-image: url(../images/mountain-bg.png);
  background-repeat: no-repeat;
  background-position: bottom left;
}

#site-content #summer_yellowstone_intro {
  background-image: url(../images/mountain-border-full.png);
  background-repeat: no-repeat;
  background-position: bottom center;
}

#site-content #jenny_intro {
  padding-top: 60px;
  padding-bottom: 15px;
}

#site-content #home_intro .page_grid_col_content,
#site-content #jenny_intro .page_grid_col_content,
#site-content #summer_yellowstone_intro .page_grid_col_content {
  border-radius: 0px 10px;
  border: 1px solid #a4bd00;
  background: rgb(244 246 248 / 60%);
  padding: 30px 40px 45px 40px;
  position: relative;
}

#site-content #home_intro .page_grid_col_content:before,
#site-content #home_intro .page_grid_col_content:after,
#site-content #jenny_intro .page_grid_col_content:before,
#site-content #jenny_intro .page_grid_col_content:after,
#site-content #summer_yellowstone_intro .page_grid_col_content:before,
#site-content #summer_yellowstone_intro .page_grid_col_content:after {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #a4bd00;
  content: "";
  -webkit-transition: 500ms ease all;
  -o-transition: 500ms ease all;
  transition: 500ms ease all;
}

#site-content #home_intro .page_grid_col_content:before,
#site-content #jenny_intro .page_grid_col_content:before,
#site-content #summer_yellowstone_intro .page_grid_col_content:before {
  top: 0;
  left: 0;
}

#site-content #home_intro .page_grid_col_content:after,
#site-content #jenny_intro .page_grid_col_content:after,
#site-content #summer_yellowstone_intro .page_grid_col_content:after {
  bottom: 0;
  right: 0;
}

.article_page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  max-width: 1200px;
  margin-top: 20px !important;
  margin-bottom: 20px;
}

article {
  padding-left: 50px;
  max-width: 825px;
  padding-right: 25px;
}

.article_page aside {
  width: -webkit-fill-available;
}

body article {
  font-size: 16px;
  line-height: 1.6em;
}

article h1 {
  font-size: 36px !important;
}

article #article_highlight {
  padding: 30px;
  background: #163044;
  color: #ffffff;
  border-radius: 10px;
  margin-bottom: 30px;

}

article #article_highlight h3 {
  color: #ffffff !important;
  font-size: 24px;
}

article #article_highlight li {
  font-size: 20px;
  line-height: 1.6em;
  margin-bottom: 20px;
}

#article_content li {
  font-size: 16px;
  margin-left: 20px;
}

article #article_ad {
  padding: 30px;
  background: #F4F6F8;
  border-radius: 10px;
  margin-bottom: 30px;
}

article #article_ad .page_grid {
  margin-bottom: auto !important;
}

article .article_ad_3cols .page_grid {
  -ms-grid-columns: 1fr 1fr 1fr !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  gap: 15px !important;

}

article .article_ad_2cols .page_grid {
  gap: 15px !important;
}

article .article_ad_3cols .page_grid .page_grid_col:first-child,
article .article_ad_2cols .page_grid .page_grid_col:first-child {
  margin-right: 0px !important;
}

/* .article_ad_2cols .page_grid .ad_block  */
.article_page .ad_block {
  width: 100% !important;
  height: 250px !important;
}

#article_next_content h6 {
  color: #a3bc01;
  font-size: 21px;
  line-height: 1.4em;
}

p.caption {
  font-size: 12px !important;
  padding-top: 5px;
}

#article_next_content h6 {
  position: relative;
}

#article_next_content h6:after {
  content: '\2192';
  position: absolute;
  top: 45%;
  right: -10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #a3bc01;
  font-weight: 600;
  left: 120px;
}

.ad_spotlight h6 {
  position: relative;
}

.ad_spotlight h6:after {
  content: '\2192';
  position: absolute;
  top: 45%;
  right: -10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #000000;
  font-weight: 600;
  left: 190px;
}

.ad_spotlight .page_grid {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

aside {
  padding-left: 25px;
  padding-right: 25px;
}

aside h5 {
  border-bottom: 2px solid #a3bc01;
}

aside h6.img-cabin {
  position: relative;
  color: #1552a9;
}

/* aside h6.img-cabin:before {
  content: '';
  position: absolute;
  left: -20px; /* Adjust as needed */
/*  top: 50%;
  transform: translateY(-50%);
  width: 51px; 
  height: 52px;
  background-image: url('assets/images/icon-img-cabin.png');
  background-size: cover;
  background-repeat: no-repeat;
} */
aside h6.img-cabin:after {
  content: '\2192';
  position: absolute;
  top: 45%;
  right: -10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #1552a9;
  font-weight: 600;
  left: 200px;
}

aside li a {
  font-size: 16px !important;
  line-height: 1.1em !important;
  text-decoration: none;
}

#article_content a {
  color: #222222;
  text-decoration: none;
  border-bottom: 1px solid #222222;
}

aside li {
  /* list-style-position: inside !important; */
  list-style: none;
  position: relative;
}

aside li:before {
  content: ">";
  position: absolute;
  left: -1em;
  font-size: 0.7em;
  color: #a3bc01;
  /* font-weight: 600; */
}

@-webkit-keyframes animateHeading {
  from {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  to {
    -webkit-transform: unset;
    transform: unset;
  }
}

@keyframes animateHeading {
  from {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  to {
    -webkit-transform: unset;
    transform: unset;
  }
}

@-webkit-keyframes boxMoveBefore {
  from {
    left: 0;
    top: 0;
  }

  to {
    left: calc(100% - 15px);
    top: calc(100% - 15px);
  }
}

@keyframes boxMoveBefore {
  from {
    left: 0;
    top: 0;
  }

  to {
    left: calc(100% - 15px);
    top: calc(100% - 15px);
  }
}

@-webkit-keyframes boxMoveAfter {
  from {
    bottom: 0;
    right: 0;
  }

  to {
    bottom: calc(100% - 15px);
    right: calc(100% - 15px);
  }
}

@keyframes boxMoveAfter {
  from {
    bottom: 0;
    right: 0;
  }

  to {
    bottom: calc(100% - 15px);
    right: calc(100% - 15px);
  }
}

@-webkit-keyframes bgAnimate {
  0% {
    background: -webkit-gradient(linear, left top, right top, from(#1552a9), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #1552a9 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  10% {
    background: -webkit-gradient(linear, left top, right top, from(#134a96), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #134a96 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  20% {
    background: -webkit-gradient(linear, left top, right top, from(#114283), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #114283 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  30% {
    background: -webkit-gradient(linear, left top, right top, from(#0f3a70), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #0f3a70 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  40% {
    background: -webkit-gradient(linear, left top, right top, from(#0d325d), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #0d325d 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  50% {
    background: -webkit-gradient(linear, left top, right top, from(#0b2a4a), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #0b2a4a 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  60% {
    background: -webkit-gradient(linear, left top, right top, from(#092237), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #092237 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  70% {
    background: -webkit-gradient(linear, left top, right top, from(#071a24), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #071a24 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  80% {
    background: -webkit-gradient(linear, left top, right top, from(#050f11), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #050f11 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  90% {
    background: -webkit-gradient(linear, left top, right top, from(#030708), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #030708 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  100% {
    background: -webkit-gradient(linear, left top, right top, from(#000), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@keyframes bgAnimate {
  0% {
    background: -o-linear-gradient(left, #1552a9 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#1552a9), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #1552a9 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  10% {
    background: -o-linear-gradient(left, #134a96 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#134a96), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #134a96 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  20% {
    background: -o-linear-gradient(left, #114283 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#114283), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #114283 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  30% {
    background: -o-linear-gradient(left, #0f3a70 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#0f3a70), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #0f3a70 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  40% {
    background: -o-linear-gradient(left, #0d325d 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#0d325d), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #0d325d 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  50% {
    background: -o-linear-gradient(left, #0b2a4a 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#0b2a4a), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #0b2a4a 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  60% {
    background: -o-linear-gradient(left, #092237 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#092237), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #092237 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  70% {
    background: -o-linear-gradient(left, #071a24 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#071a24), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #071a24 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  80% {
    background: -o-linear-gradient(left, #050f11 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#050f11), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #050f11 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  90% {
    background: -o-linear-gradient(left, #030708 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#030708), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #030708 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  100% {
    background: -o-linear-gradient(left, #000 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background: -webkit-gradient(linear, left top, right top, from(#000), color-stop(59.03%, rgba(0, 0, 0, 0))),
      url(../images/cta_bg.png);
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 59.03%),
      url(../images/cta_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@-webkit-keyframes popupContentAnime {
  from {
    -webkit-transform: translateY(-10px) rotate(1deg) scale(0.9);
    transform: translateY(-10px) rotate(1deg) scale(0.9);
    opacity: 0.5;
  }

  to {
    -webkit-transform: translateY(0) rotate(0deg) scale(1);
    transform: translateY(0) rotate(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes popupContentAnime {
  from {
    -webkit-transform: translateY(-10px) rotate(1deg) scale(0.9);
    transform: translateY(-10px) rotate(1deg) scale(0.9);
    opacity: 0.5;
  }

  to {
    -webkit-transform: translateY(0) rotate(0deg) scale(1);
    transform: translateY(0) rotate(0deg) scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes popupContentAnimeReverse {
  from {
    -webkit-transform: translateY(0) rotate(0deg) scale(1);
    transform: translateY(0) rotate(0deg) scale(1);
    opacity: 1;
  }

  to {
    -webkit-transform: translateY(-10px) rotate(1deg) scale(0.9);
    transform: translateY(-10px) rotate(1deg) scale(0.9);
    opacity: 0.5;
  }
}

@keyframes popupContentAnimeReverse {
  from {
    -webkit-transform: translateY(0) rotate(0deg) scale(1);
    transform: translateY(0) rotate(0deg) scale(1);
    opacity: 1;
  }

  to {
    -webkit-transform: translateY(-10px) rotate(1deg) scale(0.9);
    transform: translateY(-10px) rotate(1deg) scale(0.9);
    opacity: 0.5;
  }
}

@-webkit-keyframes showPopupBox {
  from {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    bottom: -100%;
  }

  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    bottom: 0;
  }
}

@keyframes showPopupBox {
  from {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    bottom: -100%;
  }

  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    bottom: 0;
  }
}

@-webkit-keyframes showPopupBoxReverse {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    bottom: 0;
  }

  to {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    bottom: -100%;
  }
}

@keyframes showPopupBoxReverse {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    bottom: 0;
  }

  to {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    bottom: -100%;
  }
}

#site-content .popup.visible-reverse {
  -webkit-animation-name: showPopupBoxReverse;
  animation-name: showPopupBoxReverse;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#site-content .popup.visible {
  -webkit-animation-name: showPopupBox;
  animation-name: showPopupBox;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#site-content .popup.visible .popup_content h2,
#site-content .popup.visible .popup_content h4,
#site-content .popup.visible .popup_content p,
#site-content .popup.visible .popup_content form {
  -webkit-animation-name: popupContentAnime;
  animation-name: popupContentAnime;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#site-content .popup.visible-reverse .popup_content h2,
#site-content .popup.visible-reverse .popup_content h4,
#site-content .popup.visible-reverse .popup_content p,
#site-content .popup.visible-reverse .popup_content form {
  -webkit-animation-name: popupContentAnimeReverse;
  animation-name: popupContentAnimeReverse;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#site-content #home_intro .page_grid_col_content:hover:after,
#site-content #jenny_intro .page_grid_col_content:hover:after,
#site-content #summer_yellowstone_intro .page_grid_col_content:hover:after {
  -webkit-animation-name: boxMoveAfter;
  animation-name: boxMoveAfter;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#site-content #home_intro .page_grid_col_content:hover:before,
#site-content #jenny_intro .page_grid_col_content:hover:before,
#site-content #summer_yellowstone_intro .page_grid_col_content:hover:before {
  -webkit-animation-name: boxMoveBefore;
  animation-name: boxMoveBefore;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#site-content #home_intro .page_grid_col_content:hover h2,
#site-content #home_intro .page_grid_col_content:hover p,
#site-content #home_intro .page_grid_col_content:hover li,
#site-content #jenny_intro .page_grid_col_content:hover h2,
#site-content #jenny_intro .page_grid_col_content:hover p,
#site-content #jenny_intro .page_grid_col_content:hover li,
#site-content #summer_yellowstone_intro .page_grid_col_content:hover h2,
#site-content #summer_yellowstone_intro .page_grid_col_content:hover p,
#site-content #summer_yellowstone_intro .page_grid_col_content:hover li {
  -webkit-animation-name: animateHeading;
  animation-name: animateHeading;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

#site-content .page_grid_col_image {
  overflow: hidden;
  border-radius: 10px;
}

#site-content .page_grid_col_image:hover img {
  -webkit-transform: scale(1.1) rotate(1deg);
  -ms-transform: scale(1.1) rotate(1deg);
  transform: scale(1.1) rotate(1deg);
}

#site-content .page_muti_columns_image {
  border-radius: 10px;
}

#site-content .page_muti_columns_image:hover img {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

#site-content .stacked_grid_col_image {
  overflow: hidden;
  border-radius: 10px;
}

#site-content .stacked_grid_col_image:hover img {
  -webkit-transform: scale(1.1) rotate(-1deg);
  -ms-transform: scale(1.1) rotate(-1deg);
  transform: scale(1.1) rotate(-1deg);
}

#site-content #home_bg_cta:hover {
  -webkit-animation-name: bgAnimate;
  animation-name: bgAnimate;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

#site-content .page_grid_col.page_grid_col_content:hover h2,
#site-content .page_grid_col.page_grid_col_content:hover p,
#site-content .page_grid_col.page_grid_col_content:hover ul,
#site-content .page_grid_col.page_grid_col_content:hover h4,
#site-content .page_grid_col.page_grid_col_content:hover a.site-button {
  -webkit-transform: rotate(-0.1deg) scale(1.03);
  -ms-transform: rotate(-0.1deg) scale(1.03);
  transform: rotate(-0.1deg) scale(1.03);
}

#site-content .page_grid_col.page_grid_col_content h2,
#site-content .page_grid_col.page_grid_col_content p,
#site-content .page_grid_col.page_grid_col_content ul,
#site-content .page_grid_col.page_grid_col_content h4,
#site-content .page_grid_col.page_grid_col_content a.site-button,
#site-content .stacked_grid_col_image img,
#site-content .page_muti_columns_image img,
#site-content .page_grid_col_image img {
  -webkit-transition: 500ms ease all;
  -o-transition: 500ms ease all;
  transition: 500ms ease all;
}

#site-content .ad_block h2 {
  margin: 0;
  color: #fff;
}

#site-content .ad_block {
  background: #1552a9;
  width: 274px;
  height: 274px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#site-content #home_intro .page_grid_col_ad,
#site-content #jenny_intro .page_grid_col_ad,
#site-content #jenny_lake_ad .page_grid_col_ad,
#site-content #summer_yellowstone_intro .page_grid_col_ad {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#site-content .page_grid .page_grid_col:first-child {
  margin-right: 45px;
}

#site-content #home_intro .page_grid,
#site-content #jenny_intro .page_grid {
  -ms-grid-columns: 60% 40%;
  grid-template-columns: 60% 40%;
}

#site-content #summer_yellowstone_intro .page_grid {
  display: block;
  max-width: 800px;
}

#site-content #summer_yellowstone_intro .page_grid_col_content {
  text-align: center;
}

#site-content #home_iternity_one,
#site-content #home_iternity_two,
#site-content #home_iternity_three,
#site-content #jenny_iternity {
  border-right: 4px solid #a4bd00;
  border-left: 4px solid #a4bd00;
  background: #f4f6f8;
  padding-top: 70px;
  padding-bottom: 40px;
}

#site-content #jenny_lake_ad,
#site-content #yellowstone_iternity {
  padding-top: 70px;
  padding-bottom: 40px;
}

#site-content .home_iternity_one .page_grid,
#site-content .home_iternity_two .page_grid,
#site-content .home_iternity_three .page_grid,
#site-content .yellowstone_iternity .page_grid {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  background: #f9f9f9;
  padding: 25px;
}

#site-content .jenny_iternity .page_grid,
#site-content .jenny_lake_ad .page_grid {
  padding: 25px;
}

#site-content .page_grid.page_grid_with_ad {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 37% 37% 26%;
  grid-template-columns: 37% 37% 26%;
}

#site-content .page_grid.page_grid_with_ad .ad_block {
  margin-left: 30px;
}

#site-content #home_featured_activities,
#site-content #home_featured_lodging,
#site-content #yellowstone_featured_activities {
  background: #163044;
  padding-top: 65px;
  padding-bottom: 50px;
}

#site-content #summer_yellowstone_activities {
  border-right: 4px solid #a4bd00;
  border-left: 4px solid #a4bd00;
  background: #f4f6f8;
  padding-top: 65px;
  padding-bottom: 50px;
}

#site-content #home_featured_activities h2,
#site-content #home_featured_lodging h2 {
  color: #fff;
  font-weight: 400;
  line-height: 1;
  font-size: 7.857rem;
  text-align: center;
  margin-bottom: 0;
  opacity: 0.6;
}

#site-content .page_muti_columns_grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

#site-content .page_muti_columns_grid_col {
  margin-right: 15px;
}

#site-content #home_featured_activities p,
#site-content #home_featured_lodging p,
#site-content #yellowstone_featured_activities p {
  color: #b9e1ff;
}

#site-content .page_muti_columns_grid_col p {
  margin-bottom: 30px;
}

#site-content .page_muti_columns_image {
  position: relative;
  margin-bottom: 50px;
}

#site-content .page_muti_columns_grid_col .page_muti_columns_image p {
  color: #fff !important;
  border-radius: 0px 10px 10px 10px;
  background: #0a1a26;
  display: inline-block;
  margin-bottom: 0;
  padding: 20px 30px;
  font-size: 1.571rem;
  font-weight: 700;
  position: absolute;
  bottom: -20px;
  margin-bottom: 0;
}

#site-content #home_lodging {
  background: #163044;
  padding-top: 60px;
  padding-bottom: 60px;
}

#site-content .stacked_grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30% 70%;
  grid-template-columns: 30% 70%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#site-content .stacked_grid_col {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 40% 60%;
  grid-template-columns: 40% 60%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#site-content #home_lodging h2,
#site-content #home_lodging h4 {
  color: #fff;
}

#site-content #home_lodging p {
  color: #b9e1ff;
}

#site-content #home_lodging h2 {
  font-size: 5rem;
  font-weight: 400;
  line-height: 5.714rem;
  opacity: 0.6;
}

#site-content .stacked_grid_col_image {
  margin-right: 30px;
}

#site-content .stacked_grid_row .stacked_grid_col:last-child {
  margin-bottom: 0;
}

#site-content .stacked_grid_row .stacked_grid_col {
  margin-bottom: 50px;
}

#site-content #home_bg_cta {
  background: -o-linear-gradient(left, #1552a9 0%, rgba(0, 0, 0, 0) 59.03%),
    url(../images/cta_bg.png);
  background: -webkit-gradient(linear, left top, right top, from(#1552a9), color-stop(59.03%, rgba(0, 0, 0, 0))),
    url(../images/cta_bg.png);
  background: linear-gradient(90deg, #1552a9 0%, rgba(0, 0, 0, 0) 59.03%),
    url(../images/cta_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 90px;
  border-bottom: 4px solid #a4bd00;
  -webkit-transition: 500ms ease all;
  -o-transition: 500ms ease all;
  transition: 500ms ease all;
}

#site-content #home_bg_cta h2,
#site-content #home_bg_cta p {
  color: #fff;
  margin-top: 0;
}

#site-content .popup {
  position: fixed;
  bottom: -100%;
  right: 0;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
  -o-transition: opacity 0.5s ease, visibility 0.5s ease;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  border-radius: 10px 0px 0px 0px;
  max-width: 575px;
  z-index: 3;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

#site-content .popup:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../images/popup_bg.png), #0c0c0c -90.849px 0px / 128.659% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 10px 0px 0px 0px;
  z-index: -1;
}

#site-content .popup_content h2,
#site-content .popup_content h4,
#site-content .popup_content p,
#site-content .popup_content form {
  -webkit-transform: translateY(-10px) rotate(1deg) scale(0.9);
  -ms-transform: translateY(-10px) rotate(1deg) scale(0.9);
  transform: translateY(-10px) rotate(1deg) scale(0.9);
  opacity: 0.5;
}

#site-content .close-btn {
  position: absolute;
  border: none;
  background: none;
  color: #fff;
  font-size: 2.5rem;
  opacity: 0.6;
  cursor: pointer;
  line-height: 1;
  top: 0;
  right: 15px;
}

#site-content .popup_content h2,
#site-content .popup_content h4,
#site-content .popup_content p {
  color: #fff;
}

#site-content form#contactForm input[type="text"],
#site-content form#contactForm input[type="email"],
#site-content form#contactForm select {
  width: 100%;
  background: transparent;
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #5d5d5d;
  color: #a4bd00;
  font-size: 1.21rem;
  font-size: clamp(1.125rem, 1.1044rem + 0.088vw, 1.21rem);
  line-height: 1.5rem;
  padding: 10px 0;
  margin-bottom: 15px;
  outline: none;
}

#site-content form#contactForm select {
  padding-right: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 0 center;
  background-size: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z' fill='%23ffffff'/%3E%3C/svg%3E");
}

#site-content form#contactForm input[type="text"]::-webkit-input-placeholder,
#site-content form#contactForm input[type="email"]::-webkit-input-placeholder {
  color: #a4bd00;
}

#site-content form#contactForm input[type="text"]::-moz-placeholder,
#site-content form#contactForm input[type="email"]::-moz-placeholder {
  color: #a4bd00;
}

#site-content form#contactForm input[type="text"]:-ms-input-placeholder,
#site-content form#contactForm input[type="email"]:-ms-input-placeholder {
  color: #a4bd00;
}

#site-content form#contactForm input[type="text"]::-ms-input-placeholder,
#site-content form#contactForm input[type="email"]::-ms-input-placeholder {
  color: #a4bd00;
}

#site-content form#contactForm input[type="text"]::-webkit-input-placeholder,
#site-content form#contactForm input[type="email"]::-webkit-input-placeholder {
  color: #a4bd00;
}

#site-content form#contactForm input[type="text"]::-moz-placeholder,
#site-content form#contactForm input[type="email"]::-moz-placeholder {
  color: #a4bd00;
}

#site-content form#contactForm input[type="text"]:-ms-input-placeholder,
#site-content form#contactForm input[type="email"]:-ms-input-placeholder {
  color: #a4bd00;
}

#site-content form#contactForm input[type="text"]::-ms-input-placeholder,
#site-content form#contactForm input[type="email"]::-ms-input-placeholder {
  color: #a4bd00;
}

#site-content form#contactForm input[type="text"]::placeholder,
#site-content form#contactForm input[type="email"]::placeholder {
  color: #a4bd00;
}

#site-content #contactForm input[type="submit"] {
  margin-top: 15px;
}

#site-content .disable-popup {
  display: none !important;
}

#site-content #jenny_iternity .page_grid {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#site-content #jenny_intro .page_grid_col_content p {
  font-size: 1.25rem;
  line-height: 1.2;
}

#site-content #jenny_iternity .page_grid:last-child {
  margin-bottom: 30px;
  padding-bottom: 25px;
}

#site-content #jenny_iternity .page_grid {
  margin-bottom: 0;
  padding-bottom: 15px;
}

#site-content #yellowstone_featured_activities .page_muti_columns_grid {
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 50px 30px;
}

#site-content #summer_yellowstone_activities .page_muti_columns_grid {
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px 30px;
}

#site-content #yellowstone_featured_activities .page_muti_columns_grid_col,
#site-content #summer_yellowstone_activities .page_muti_columns_grid_col {
  margin-right: 0;
}

#site-content #yellowstone_featured_activities .page_muti_columns_image img,
#site-content #summer_yellowstone_activities .page_muti_columns_image img {
  border-radius: 10px;
}

#site-content #summer_yellowstone_activities .page_muti_columns_grid_col {
  background: #fff;
}

#site-content #summer_yellowstone_activities p {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

#site-content #summer_yellowstone_activities a.site-button {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

#site-content #summer_yellowstone_activities ul {
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#site-content #summer_yellowstone_activities ul li {
  list-style: none;
  border-radius: 50px;
  background: #E4E4E4;
  padding: 10px;
  line-height: 1.1 !important;
}

#site-content #summer_yellowstone_activities ul li:first-child {
  margin-right: 15px;
}

.categorybox .advert {
  padding: 2px;
  color: #343333;
  font-size: 12px !important;
}

.categorybox-image {
  position: relative;
}

.categorybox img {
  margin-bottom: -5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 240px;
  max-height: 240px;

}

.categorybox-link {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff !important;
  padding: 6px 9pt 6px 8px;
}

.package-title,
.webcam-title {
  background-color: rgba(27, 48, 67, .7);
  width: 100%;
}

.categorybox-link {
  background-color: rgba(27, 48, 67, .7);
  width: auto;
}

strong {
  font-weight: 600;
}

.fa {
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.fa,
.fa-stack {
  display: inline-block;
}

.fa-external-link:before {
  content: "\f08e";
}

:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.categorybox h5 {
  margin-top: 0;
  padding: 14px;
  color: #333;
  font-family: Cabin, sans-serif;
  border: 1px solid #e5e5e5;
  background: #f9f9f9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.categorybox h5 a {
  color: #2851a3;
}

.categorybox p,
.categorybox p a {
  margin-top: 10px !important;
  font-size: 14px !important;
  line-height: 1.5em !important;
  color: #333333 !important;
}

.categorybox {
  margin-right: 20px;
}

.categorybox:last-child {
  margin-right: 0px;
}

.ad-row {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #F4F6F8;
  margin-bottom: 50px;
}

.row-ads-3,
.row-ads-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  max-width: 1200px;
  width: 95%;
  margin: auto;
}

.rating {
  padding: 10px 4px !important;
  display: flex;
}

.rating strong {
  font-size: 16px !important;
}

.fa-star:before {
  content: "\f005";
  color: #FFA41E;
}

.categorybox-image a {
  font-size: 18px !important;
  text-decoration: none;
  line-height: 1.4em !important;
}

.ad-row h3 {
  font-size: 24px !important;
  line-height: 2em !important;
  text-align: center;
}

.ad-row a {
  text-decoration: none;
}

.star.checked {
  width: 22px;
  height: 22px;
  background-image: url(../images/star.png);
  background-repeat: no-repeat;
}

.half-star.checked {
  width: 22px;
  height: 22px;
  background-image: url(../images/half-star.png);
  background-repeat: no-repeat;
}

#site-content p,
#article_content a {
  font-size: 16px;
}

#article_content li {
  line-height: 1.6;
}

site-content h5,
#site-content h5>span,
#site-content h5>a,
#site-content h5>a>span {
  font-size: 2.0rem;
  line-height: 1.6;
}

#site-content h6,
#site-content h6>span,
#site-content h6>a,
#site-content h6>a>span {
  font-size: 2.1rem;
}

.star-ratings {
  unicode-bidi: bidi-override;
  color: #c5c5c5;
  margin: -2px auto 0;
  position: relative;
  padding: 0;

}

.ad-row .star-ratings {
  margin-top: 7px !important;
  display: inline-block !important;
}

.star-ratings-top {
  color: #f3a843;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: inline-block;
  top: 0;
  left: 0;
  overflow: hidden;
}

.star-ratings-bottom {
  padding: 0;
  display: inline-block;
  z-index: 0;
}

.star-ratings span {
  font-size: 15px !important;
}

/********************************************************************************/
/********************************************************************************/
/*********************************Media Query Start Here*************************/
/********************************************************************************/
/********************************************************************************/

@media only screen and (max-width: 991px) {
  .article_page {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  article {
    padding-left: 30px;
    max-width: 100%;
    padding-right: 30px;
  }

  aside {
    padding-left: 30px;
    padding-right: 30px;
  }

  article #article_ad .page_grid .page_grid_col_ad {
    margin-bottom: 15px;
  }

  article .page_grid .page_grid_col:first-child {
    margin-bottom: auto;
  }

  article #article_ad .page_grid .page_grid_col_ad:last-child {
    margin-bottom: 0 !important;
  }

  aside .img-side-stay-cabin img {
    width: auto !important;
  }

  .row-ads-3,
  .row-ads-2 {
    display: block;
    max-width: 480px;
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 767px) {
  #site-content #home-banner:before {
    display: none;
  }

  #site-content .page_banner_caption {
    position: initial;
    padding-left: 30px;
    padding-right: 30px;
  }

  #site-content .page_banner_caption h1,
  #site-content .page_banner_caption h4 {
    color: #000;
  }

  #site-content .banner_search ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  #site-content .banner_search ul li {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }

  #site-content .banner_search ul li select {
    width: 100%;
  }

  #site-content .banner_search {
    padding: 15px;
  }

  #site-content .page_grid {
    display: block;
  }

  #site-content #home_intro .page_grid_col_content,
  #site-content #jenny_intro .page_grid_col_content,
  #site-content #summer_yellowstone_intro .page_grid_col_content {
    margin-right: 0;
    margin-bottom: 30px;
    padding: 15px;
  }

  #site-content .page_grid .page_grid_col:first-child {
    margin-right: 0;
    margin-bottom: 15px;
  }

  #site-content .page_grid.page_grid_with_ad {
    display: block;
  }

  #site-content .page_grid.page_grid_with_ad .ad_block {
    margin-left: 0;
    margin-top: 15px;
  }

  #site-content #home_featured_activities h2,
  #site-content #home_featured_lodging h2 {
    font-size: 3.5rem;
  }

  #site-content .page_muti_columns_grid {
    display: block;
  }

  #site-content .page_muti_columns_grid .page_muti_columns_grid_col:last-child {
    margin-bottom: 0;
  }

  #site-content .page_muti_columns_grid .page_muti_columns_grid_col {
    margin-right: 0;
    margin-bottom: 30px;
  }

  #site-content .stacked_grid {
    display: block;
  }

  #site-content #home_lodging h2 {
    font-size: 3.5rem;
    line-height: 1.2;
    text-align: center;
  }

  #site-content .stacked_grid_row .stacked_grid_col {
    display: block;
  }

  #site-content .stacked_grid_col_image {
    margin-right: 0;
    margin-bottom: 15px;
  }

  #site-content .page_banner_caption h1 {
    margin-top: 15px;
  }

  #site-content #home_iternity_one,
  #site-content #home_iternity_two,
  #site-content #home_iternity_three,
  #site-content #yellowstone_iternity,
  #site-content #jenny_iternity,
  #site-content #jenny_lake_ad {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #site-content #home_featured_activities,
  #site-content #home_featured_lodging,
  #site-content #yellowstone_featured_activities,
  #site-content #summer_yellowstone_activities {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #site-content .page_grid,
  #site-content .stacked_grid_wrapper,
  #site-content .bg_cta,
  #site-content .page_muti_columns_grid {
    padding-left: 30px;
    padding-right: 30px;
  }

  #site-content #home_iternity_one .page_grid,
  #site-content #home_iternity_two .page_grid,
  #site-content #home_iternity_three .page_grid,
  #site-content #yellowstone_iternity .page_grid {
    margin-left: 30px;
    margin-right: 30px;
  }

  #site-content #jenny_iternity .page_grid,
  #site-content #jenny_lake_ad .page_grid,
  #site-content #jenny_iternity .page_grid,
  #site-content #yellowstone_featured_activities .page_muti_columns_grid,
  #site-content #summer_yellowstone_activities .page_muti_columns_grid {
    margin-left: 0;
    margin-right: 0;
  }

  #site-content .ad_block,
  #site-content .page_grid.page_grid_with_ad .ad_block {
    height: auto;
  }

  #site-content .page_grid.page_grid_with_ad .ad_block {
    width: auto;
  }

  #site-content .ad_block {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #site-content #home-banner:before {
    display: none;
  }

  #site-content .page_banner_caption {
    position: initial;
    padding-left: 30px;
    padding-right: 30px;
  }

  #site-content .page_banner_caption h1,
  #site-content .page_banner_caption h4 {
    color: #000;
  }

  #site-content .banner_search ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  #site-content .banner_search ul li {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }

  #site-content .banner_search ul li select {
    width: 100%;
  }

  #site-content .banner_search {
    padding: 15px;
  }

  #site-content .page_grid {
    display: block;
  }

  #site-content #home_intro .page_grid_col_content,
  #site-content #jenny_intro .page_grid_col_content,
  #site-content #summer_yellowstone_intro .page_grid_col_content {
    margin-right: 0;
    margin-bottom: 30px;
    padding: 15px;
  }

  #site-content .page_grid .page_grid_col:first-child {
    margin-right: 0;
    margin-bottom: 15px;
  }

  #site-content .page_grid.page_grid_with_ad {
    display: block;
  }

  #site-content .page_grid.page_grid_with_ad .ad_block {
    margin-left: 0;
    margin-top: 15px;
  }

  #site-content #home_featured_activities h2,
  #site-content #home_featured_lodging h2 {
    font-size: 3.5rem;
  }

  #site-content .page_muti_columns_grid {
    display: block;
  }

  #site-content .page_muti_columns_grid .page_muti_columns_grid_col:last-child {
    margin-bottom: 0;
  }

  #site-content .page_muti_columns_grid .page_muti_columns_grid_col {
    margin-right: 0;
    margin-bottom: 30px;
  }

  #site-content .stacked_grid {
    display: block;
  }

  #site-content #home_lodging h2 {
    font-size: 3.5rem;
    line-height: 1.2;
    text-align: center;
  }

  #site-content .stacked_grid_row .stacked_grid_col {
    display: block;
  }

  #site-content .stacked_grid_col_image {
    margin-right: 0;
    margin-bottom: 15px;
  }

  #site-content .page_banner_caption h1 {
    margin-top: 15px;
  }

  #site-content #home_iternity_one,
  #site-content #home_iternity_two,
  #site-content #home_iternity_three,
  #site-content #yellowstone_iternity,
  #site-content #jenny_iternity,
  #site-content #jenny_lake_ad {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #site-content #home_featured_activities,
  #site-content #home_featured_lodging,
  #site-content #yellowstone_featured_activities,
  #site-content #summer_yellowstone_activities {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #site-content .page_grid,
  #site-content .stacked_grid_wrapper,
  #site-content .bg_cta,
  #site-content .page_muti_columns_grid {
    padding-left: 30px;
    padding-right: 30px;
  }

  #site-content #home_iternity_one .page_grid,
  #site-content #home_iternity_two .page_grid,
  #site-content #home_iternity_three .page_grid,
  #site-content #yellowstone_iternity .page_grid {
    margin-left: 30px;
    margin-right: 30px;
  }

  #site-content #jenny_iternity .page_grid,
  #site-content #jenny_lake_ad .page_grid,
  #site-content #jenny_iternity .page_grid,
  #site-content #yellowstone_featured_activities .page_muti_columns_grid,
  #site-content #summer_yellowstone_activities .page_muti_columns_grid {
    margin-left: 0;
    margin-right: 0;
  }

  #site-content .ad_block,
  #site-content .page_grid.page_grid_with_ad .ad_block {
    height: auto;
  }

  #site-content .page_grid.page_grid_with_ad .ad_block {
    width: auto;
  }

  #site-content .ad_block {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {

  #site-content .page_grid,
  #site-content .banner_search,
  #site-content .stacked_grid_wrapper,
  #site-content .bg_cta,
  #site-content .page_muti_columns_grid {
    padding-left: 30px;
    padding-right: 30px;
  }

  #site-content #home_iternity_one .page_grid,
  #site-content #home_iternity_two .page_grid,
  #site-content #home_iternity_three .page_grid,
  #site-content #yellowstone_iternity .page_grid,
  #site-content #jenny_iternity .page_grid,
  #site-content #jenny_lake_ad .page_grid,
  #site-content #yellowstone_featured_activities .page_muti_columns_grid,
  #site-content #summer_yellowstone_activities .page_muti_columns_grid {
    margin-left: 30px;
    margin-right: 30px;
  }

  #site-content .page_grid.page_grid_with_ad .ad_block {
    width: auto;
  }

  #site-content .ad_block {
    width: 100%;
  }

  #site-content #home_lodging h2 {
    font-size: 4rem;
    line-height: 4.741rem;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1439px) {

  #site-content .page_grid,
  #site-content .banner_search,
  #site-content .stacked_grid_wrapper,
  #site-content .bg_cta,
  #site-content .page_muti_columns_grid {
    padding-left: 30px;
    padding-right: 30px;
  }

  #site-content #home_iternity_one .page_grid,
  #site-content #home_iternity_two .page_grid,
  #site-content #home_iternity_three .page_grid,
  #site-content #yellowstone_iternity .page_grid,
  #site-content #jenny_iternity .page_grid,
  #site-content #jenny_lake_ad .page_grid,
  #site-content #yellowstone_featured_activities .page_muti_columns_grid,
  #site-content #summer_yellowstone_activities .page_muti_columns_grid {
    margin-left: 30px;
    margin-right: 30px;
  }

  #site-content .page_grid.page_grid_with_ad .ad_block {
    width: auto;
  }

  #site-content .ad_block {
    width: 100%;
  }

  #site-content #home_lodging h2 {
    font-size: 4rem;
    line-height: 4.741rem;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1679px) {}

@media only screen and (min-width: 1680px) and (max-width: 1919px) {}

@media only screen and (min-width: 1920px) and (max-width: 2559px) {}

@media (min-width: 2560px) {}