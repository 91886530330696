/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.9.2
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/ 

/*Service Block v2 (used in page_404_error2.html)
------------------------------------*/
.service-block-v2 .service-block-in {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 15px;
  background: rgba(255,255,255,0.2);
}

.service-block-v2 .service-block-in i {
  font-size: 40px;
}

.service-block-v2 .service-block-in h4 {
  line-height: 25px;
}

.service-block-v2 .service-block-in p {
  margin-bottom: 20px;
}

.service-block-v2 .service-block-in i,
.service-block-v2 .service-block-in h4,
.service-block-v2 .service-block-in p {
  color: #fff;
}


/*Colored Content Boxes
------------------------------------*/
.service-block {
	padding: 20px 30px;
	text-align: center;
	margin-bottom: 20px;
}

.service-block p,
.service-block h2 {
	color: #fff;
}

.service-block h2 a:hover{
	text-decoration: none;
}


/* Funny Boxes
------------------------------------*/
.funny-boxes {
	background: #f7f7f7;
	padding: 20px 20px 15px;
	transition:all 0.3s ease-in-out;
}

.funny-boxes h2 {
	margin-top: 0;
	font-size: 18px;
	line-height: 20px;
}

.funny-boxes h2 a {
	color: #555;
}

.funny-boxes p a {
	color: #72c02c;
}

.funny-boxes .funny-boxes-img li {
	font-size: 12px;
	margin-bottom: 2px;
}

.funny-boxes .funny-boxes-img li i {
	color: #72c02c;
	font-size: 12px;
	margin-right: 5px;
}

@media (max-width: 992px) {
	.funny-boxes .funny-boxes-img li {
		display: inline-block;
	}
}

.funny-boxes .funny-boxes-img img {
	margin: 5px 10px 15px 0;
}

.funny-boxes ul.funny-boxes-rating li {
	display: inline-block;
}

.funny-boxes ul.funny-boxes-rating li i {
	color: #f8be2c;
	cursor: pointer;
	font-size: 14px;
}

.funny-boxes ul.funny-boxes-rating li i:hover {
	color: #f8be2c;
}

/*Funny Colored Boxes*/
.funny-boxes-colored p,
.funny-boxes-colored h2 a,
.funny-boxes-colored .funny-boxes-img li,
.funny-boxes-colored .funny-boxes-img li i {
	color: #fff;
}

/*Interactive Slider v2
------------------------------------*/
.interactive-slider-v2 {
  z-index: 1;
  padding: 200px 0;
  position: relative;
  text-align: center;
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2-md {
  padding: 250px 0;
}

.interactive-slider-v2.img-v1 {
  background: url(../img/bg/25.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2.img-v2 {
  background: url(../img/bg/14.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2.img-v3 {
  background: url(../img/bg/img8.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2.img-v4 {
  background: url(../img/bg/img9.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2.intro-promo-img {
  background: url(../img/intro/promo.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,.4);
}

.intro-promo-cover-dark:after {
  background: rgba(0,0,0,.8);
}
.intro-promo-cover-light:after {
  background: rgba(255,255,255,.9);
}

.interactive-slider-v2 h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 600;
  line-height: 80px;
  text-transform: uppercase;
}

.interactive-slider-v2 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  font-family: "Open Sans", Arial, sans-serif;
}

@media (max-width: 768px) {
  .interactive-slider-v2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------
	[34. Blog Grid]
----------------------------------------------------*/
.blog-grid img {
  margin-bottom: 15px;
}

.blog-grid h3 {
  font-size: 22px;
  margin: 0 0 10px;
  line-height: 1.4;
}

.blog-grid h2.blog-grid-title-lg {
  font-size: 28px;
  line-height: 1.4;
  margin: 0 0 10px;
}

.blog-grid h3.blog-grid-title-sm {
  font-size: 15px;
  line-height: 1.4;
}

.blog-grid h3 a {
  color: #333;
}

.blog-grid p {
  margin-bottom: 15px;
}

.blog-grid-inner {
  padding: 20px;
  background: #fff;
  margin: -70px 40px 0;
  position: relative;
}

/*Blog Grid Gradient*/
.blog-grid .blog-grid-grad {
  position: relative;
}

.blog-grid .blog-grid-grad i {
  top: 10px;
  left: 10px;
  z-index: 1;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  display: inline-block;
  background: rgba(0,0,0,0.5);
}

/*Blog Grid Info*/
.blog-grid .blog-grid-info {
  padding-left: 0;
  list-style: none;
}

.blog-grid .blog-grid-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block;
}

.blog-grid .blog-grid-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px;
}

.blog-grid .blog-grid-info li:first-child:before {
  content: " ";
  margin-right: 0;
}

.blog-grid .blog-grid-info li a {
  color: #888;
}

.blog-grid .blog-grid-info li a:hover {
  text-decoration: none;
}

/*Read More Link*/
.blog-grid a.r-more {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  display: inline-block;
}

.blog-grid a.r-more:hover {
  text-decoration: none;
}

/*--------------------------------------------------
  [35. Blog Thumb]
----------------------------------------------------*/
.blog-thumb {
  overflow: hidden;
}

/*Blog Grid Hover*/
.blog-thumb .blog-thumb-hover {
  float: left;
  position: relative;
  margin-right: 15px;
}

.blog-thumb .blog-thumb-hover:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.35s, transform 0.35s;
}

.blog-thumb .blog-thumb-hover img {
  width: 120px;
  height: auto;
}

.blog-thumb .blog-thumb-hover img.video-play-btn {
  width: 30px;
  height: 30px;
}

/*Icon Hover Gradient*/
.blog-thumb .blog-thumb-hover .hover-grad {
  left: 0;
  top: 50%;
  z-index: 1;
  opacity: 0;
  width: 100%;
  color: #fff;
  font-size: 20px;
  margin-top: -14px;
  text-align: center;
  position: absolute;
  display: inline-block;
  transition: all 0.65s, transform 0.65s;
}

.blog-thumb .blog-thumb-hover:hover .hover-grad {
  opacity: 1;
  transform 0.55s;
  transition: opacity 0.55s,
}

/*Blog Thumb Description*/
.blog-thumb .blog-thumb-desc {
  overflow: hidden;
}

.blog-thumb .blog-thumb-desc h3 {
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 400;
  line-height: 1.4;
}

.blog-thumb .blog-thumb-desc h3 a {
  color: #555;
}

/*Blog Grid Info*/
.blog-thumb .blog-thumb-info {
  padding-left: 0;
  list-style: none;
}

.blog-thumb .blog-thumb-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block;
}

.blog-thumb .blog-thumb-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px;
}

.blog-thumb .blog-thumb-info li:first-child:before {
  content: " ";
  margin-right: 0;
}

.blog-thumb .blog-thumb-info li a {
  color: #888;
}

.blog-thumb .blog-thumb-info li a:hover {
  color: #e74c3c;
}

/*Blog Thumb Circle
------------------------------------*/
.blog-thumb.blog-thumb-circle .blog-thumb-hover:after {
  border-radius: 50% !important;
}

.blog-thumb.blog-thumb-circle .blog-thumb-hover img {
  width: 50px;
  height: 50px;
}

.blog-thumb.blog-thumb-circle .blog-thumb-hover .hover-grad {
  font-size: 15px;
  margin-top: -10px;
}

/*Blog Thumb Colors
------------------------------------*/
.blog-thumb .blog-thumb-hover:hover:after {
  background: rgba(114,192,44,0.9);
}
.blog-grid .blog-grid-grad i:hover {
  background: rgba(114,192,44,1);
}
}

/*--------------------------------------------------
	[46. Bootstrap Carousels]
----------------------------------------------------*/
/* Bootstrap Carousel v1
------------------------------------*/
.carousel-v1 .carousel-caption {
	left: 0;
	right: 0;
	bottom: 0;
	padding: 7px 15px;
	background: rgba(0, 0, 0, 0.7);
}

.carousel-v1 .carousel-caption p {
	color: #fff;
	margin-bottom: 0;
}

.carousel-v1 .carousel-arrow a.carousel-control {
	opacity: 1;
	font-size:30px;
	height:inherit;
	width: inherit;
	background: none;
	text-shadow: none;
	position: inherit;
}

.carousel-v1 .carousel-arrow a i {
	top: 50%;
	opacity: 0.6;
	background: #000;
	margin-top: -18px;
	padding: 2px 12px;
	position: absolute;
}
.carousel-v1 .carousel-arrow a i:hover {
	opacity: 0.8;
}

.carousel-v1 .carousel-arrow a.left i {
	left: 0;
}
.carousel-v1 .carousel-arrow a.right i {
	right: 0;
}

/* Bootstrap Carousel v2
------------------------------------*/
.carousel-v2 .carousel-control,
.carousel-v2 .carousel-control:hover {
	opacity: 1;
	text-shadow: none;
}

.carousel-v2 .carousel-control.left,
.carousel-v2 .carousel-control.right {
	top: 50%;
	z-index: 5;
	color: #eee;
	width: 45px;
	height: 45px;
	font-size: 30px;
	margin-top: -22px;
	position: absolute;
	text-align: center;
	display: inline-block;
	border: 2px solid #eee;
	background: rgba(0,0,0,0.1);
}

.carousel-v2 .carousel-control:hover {
	background: rgba(0,0,0,0.3);
	transition: all 0.4s ease-in-out;
}

.carousel-v2 .carousel-control.left {
	left: 20px;
}

.carousel-v2 .carousel-control.right {
	right: 20px;
}

.carousel-v2 .carousel-control .arrow-prev,
.carousel-v2 .carousel-control .arrow-next {
	top: -5px;
	position: relative;
}

.carousel-v2 .carousel-control .arrow-next {
	right: -2px;
}


@media (min-width: 768px) {
	.carousel-indicators {
		bottom: 10px;
	}
}

/*--------------------------------------------------
	[49. Paginations]
----------------------------------------------------*/

/*Pegination*/
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span,
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius: 0;
}

.pagination li a {
	color: #777;
	padding: 5px 15px;
}

.pagination li a:hover {
	color: #fff;
	background: #5fb611;
	border-color: #5fb611;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	border-color: #72c02c;
	background-color: #72c02c;
}

/*Pagination Without Space*/
.pagination-no-space .pagination {
	margin: 0;
}

/* Service Block
------------------------------------*/
.service {
	overflow: hidden;
	margin-bottom: 10px;
	padding: 15px 15px 10px;
}
.service:hover {
	background: #fcfcfc;
	box-shadow: 0 0 5px #ddd;
	transition: box-shadow 0.2s ease-in-out;
}
.service:hover i {
	color: #656565;
}

.service .desc {
	padding: 0 15px;
	overflow: hidden;
}
.service .desc h4 {
	font-size: 22px;
	line-height: 25px;
}

.service .service-icon {
	float: left;
	padding: 10px;
	color: #72c02c;
	font-size: 35px;
	text-align: center;
  transition: all 0.4s ease-in-out;
}
.service .service-icon.icon-cogs {
	font-size: 37px;
}
.service .service-icon.icon-plane {
	font-size: 43px;
}

/*Servive Blocks
------------------------------------*/
/*Service Alternative Block*/
.service-alternative .service:hover {
	background: #76ca2c;
	transition: all 0.4s ease-in-out;
}
.service-alternative .service:hover i,
.service-alternative .service:hover p,
.service-alternative .service:hover h4 {
	color: #fff;
}

/*Servive Block Versions*/
.service-v1 h2 {
	margin: 15px 0 10px;
	font-size: 20px;
	line-height: 28px;
}

.service-v1 p {
	text-align: justify;
}

/*Background Opacity
------------------------------------*/
.service-or {
	overflow: hidden;
	position: relative;
}

.service-bg {
	width: 250px;
	padding: 60px;
	position: absolute;
	top: -10px;
	right: -100px;
	background-color: rgba(255,255,255,0.1);
	transform: rotate(55deg);
}

/*remove padding from "Col-"*/
.no-space {
	padding: 0;
}

.no-space-left {
	padding-left: 0;
	padding-right: 15px !important;
}

.no-space-right {
	padding-right: 0;
	padding-left: 15px !important;
}