/*/////////
/// Customizations to Standard Unify Templates
//////////*/

/*//////////
/// Colors - AllTrips Brand
/// Body
/// Links
/// Fonts
/// Headings
/// Buttons
/// Featured Content
/// Blog Grid
/// Header
/// Forms
/// Sub Navigation
/// Pub Cluster Navigation
/// Category Box
/// Category Pod Links
/// Image Carousel
/// Content
/// Advertising
/// Landing Content
/// Webcams
/// Packages
/// Cabins CTA
/// Maps
/// Spotlights
/// Footer
/// SEO H1 & Breadcrumb
/// Homepage
/// Spacers
//////////*/

/*//////////
/// Colors - AllTrips Brand
//////////*/

.at-midnight { color: #1b3043; } /* Hover Lighter - #485968 | Hover Darker - #12212e */
.at-dark { color: #222222; }
.at-blue { color: #2851a3; } /* Hover Darker - #005151 */
.at-gray { color: #808895; }
.at-fire { color: #e76325; }
.at-green { color: #88a32e; }
.at-orange { color: #ed8c3b; }
.at-lime { color: #a9bd38; }
.at-mango { color: #f3a843; }  /* Hover Lighter - #f6c27b */

/*//////////
/// Body
/////////*/

body {
  position: relative;
}
body.header-fixed-space-v2 {
  padding-top: 98px;
}

/*//////////
/// Links
/////////*/

a { color: #2851a3; }
a:hover { color: #1b3043; }

/*//////////
/// Fonts
//////////*/

/* Local */
@font-face {
  font-family: 'TradeGothic';
  src: url('../fonts/tradegothicnextsrpro-hvcn-webfont.eot');
  src: url('../fonts/tradegothicnextsrpro-hvcn-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/tradegothicnextsrpro-hvcn-webfont.woff') format('woff'),
       url('../fonts/tradegothicnextsrpro-hvcn-webfont.ttf') format('truetype'),
       url('../fonts/tradegothicnextsrpro-hvcn-webfont.svg#TradeGothicNextSRProHvCn') format('svg');
  font-weight: normal;
  font-style: normal;
}
.raleway {font-family: Raleway, 'sans-serif';}
.fw-100 {font-weight: 100;}
.fs-2 {font-size: 2rem;}
.fs-25 {font-size: 2.5rem;}
.fs-28 {font-size: 2.8rem;}
.fs-3 {font-size: 3rem;}
.fs-4 {font-size: 4rem;}
.fs-5 {font-size: 5rem;}

/*//////////
/// Headings
//////////*/

.title-v4 {
  color: #222;
  font-size: 2.2rem;
  border-bottom: 2px solid #a9bd38;
  text-transform: capitalize;
}
.title-v4 small {
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}

.title-gray {
  color: #b7b7b7;
  font-size: 2.2rem;
  margin: 0 0 20px;
  font-weight: bold;
  position: relative;
  text-transform: capitalize;
}

/*//////////
/// Buttons
//////////*/
.btn-u.btn-u-default {
	background: #2851a3;
}
.btn-u.btn-u-default:hover,
.btn-u.btn-u-default:focus,
.btn-u.btn-u-default:active,
.btn-u.btn-u-default.active,
.open .dropdown-toggle.btn-u.btn-u-default {
	background: #1b3043;
}
.btn-u.btn-u-split-default.dropdown-toggle {
	border-left: solid 1px #1b3043;
}
.btn-primary {
  color: #ffffff;
  background-color: #2851a3;
  border-color: #006b6b;
}
.btn-primary a {
	color: #ffffff;
}
.btn-primary a:hover {
	color: #eeeeee;
}
.btn-primary.active, .btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover, .open>.dropdown-toggle.btn-primary {
  background-color: #005151;
  border-color: #002d2d;
}
.btn-lime {
  color: #ffffff;
  background-color: #a9bd38;
  border-color: #88a32e;
}
.btn-lime a {
	color: #ffffff;
}
.btn-lime a:hover {
	color: #eeeeee;
}
.btn-lime.active, .btn-lime.focus, .btn-lime:active, .btn-lime:focus, .btn-lime:hover, .open>.dropdown-toggle.btn-lime {
  background-color: #88a32e;
  border-color: #a9bd38;
}

/*//////////
/// Featured Content Boxes
//////////*/

.masonry-box .masonry-box-in.col-6 {
  width: 50%;
}
.img-featured {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.featured-grid a:hover {
  text-decoration: none;
}
.featured-image {
  position: relative;
}
.featured-grid h5 {
  position: absolute;
  top: 0;
  right: 5px;
  background-color: rgba(169, 189, 56,.7);
  color: #ffffff;
  padding: 6px 9pt 6px 8px;
}
.featured-grid h3 {
  bottom: 0;
  background-color: #1b3043;
  color: #ffffff;
  padding: 8px 12px;
  margin: 0;
  width: 100%;
  font-weight: 600;
}
.featured-grid h3 a {
  color: #ffffff;
}
.featured-grid p {
  padding: 14px;
  font-size: 1.6rem;
  color: #333;
  font-family: 'Cabin', sans-serif;
  min-height: 110px;
  border: 1px solid #e5e5e5;
  background: #f9f9f9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.featured-grid h4 {
  margin-top: 0;
  padding: 20px;
  font-size: 2.1rem;
  font-weight: 500;
  color: #333;
  font-family: 'Cabin', sans-serif;
  border: 1px solid #e5e5e5;
  background: #f9f9f9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/*//////////
/// Blog Grid
//////////*/

.blog-grid h3 a:hover {
  color: #2851a3;
}
.blog-grid .blog-grid-info li a:hover {
  color: #2851a3;
}
.blog-grid a.r-more {
  color: #2851a3;
  font-size: 1.4rem;
  font-style: normal;
  border-bottom: 1px solid #2851a3;
}
.blog-grid a.r-more:hover {
  color: #1b3043;
}
.blog-grid .blog-grid-grad i:hover {
  background: rgba(0,150,150,1);
}
.blog-thumb .blog-thumb-desc h3 a:hover {
  color: #1b3043;
}
.blog-thumb .blog-thumb-info li a:hover {
  color: #2851a3;
  text-decoration: none;
}
.blog-thumb-v3 h3 a:hover {
  color: #2851a3;
}
.blog-thumb .blog-thumb-hover:hover:after {
  background: rgba(0,150,150,0.9);
}

/*//////////
/// Header
//////////*/

.header-v8 .dropdown-menu {
	border-top: solid 3px #2851a3;
}
.header-v8 .dropdown-menu .active > a,
.header-v8 .dropdown-menu li > a:hover {
	color: #2851a3;
}
.header-v8 .navbar-nav .open .dropdown-menu > li > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > li > a:focus {
	color: #2851a3;
}
.header-v8 .navbar-nav .open .dropdown-menu > .active > a,
.header-v8 .navbar-nav .open .dropdown-menu > .active > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > .active > a:focus {
	color: #2851a3;
}
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a,
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a:focus {
	color: #2851a3;
}
.header-v8 .navbar-nav > li > a:hover {
	color: #2851a3;
}
.header-v8 .navbar-nav > .active > a,
.header-v8 .navbar-nav > .active > a:hover,
.header-v8 .navbar-nav > .active > a:focus {
	color: #2851a3 !important;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-link-list li a:hover {
	color: #2851a3;
}
.blog-topbar {
	background: #12212e;
}
.topbar-list {
	float: none;
}
.topbar-list li:before {
  top: 11px;
  left: -3px;
  width: 1px;
  height: 9px;
  content: ' ';
  background: #2851a3;
  position: absolute;
}
.header-v8 .navbar {
	border-bottom: 4px solid #a9bd38;
	background: #1b3043;
}
.navbar-brand {
	width: 400px;
}
/* Brand - Logo AllTrips Text*/
.logo h5 {
	/*font-family: 'Cabin', sans-serif;*/
	font-family: "TradeGothic", Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-size: 2rem;
	color: #1b3043;
	margin-top: 4px;
	margin-bottom: 0;
}
.logo h5 a {
	color: #ffffff;
}
/* Brand - Logo Pub Name */
.logo h4 {
	font-family: 'Roboto Slab', sans-serif;
	font-weight: 700;
	font-size: 2.3rem;
	line-height: 20px;
}
.logo h4 a {
	color: #ffffff;
}
/*Shrink the Brand Text*/
.header-fixed .header-v8.header-fixed-shrink .logo h5 {
	font-size: 1.4rem;
	margin-top: 0;
}
.header-fixed .header-v8.header-fixed-shrink .logo h4 {
	font-size: 2rem;
	line-height: 6px;
}
.logo img {
	float: left;
	margin-right: 12px;
}
.btn-mainnav {
  color: #ffffff;
  background-color: #12212e;
  border-color: #e5e5e5;
  font-weight: 700;
}
.btn-mainnav:hover {
  color: #ffffff;
  background-color: #2851a3;
  border-color: #e5e5e5;
}
.header-v8 .navbar-nav > li > a {
  padding: 20px 10px;
  font-size: 16px;
  text-transform: uppercase;
  color: #2851a3;
}
.header-v8 .navbar-nav > li > a:hover {
  color: #1b3043;
}
.dropdown-menu {
	font-size: 20px;
}
.header-v8 .mega-menu .dropdown-menu {
  background: #fbf9f5;
  border-top: solid 4px #2851a3;
}
.header-v8 .navbar-toggle .icon-bar {
  background: #fff;
}
.header-v8 .dropdown-menu>li>a {
  padding: 2px 12px; 
}
.header-v8 .dropdown-menu li > a:hover {
  color: #2851a3;
}
/*Mega Menu*/
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu li {
  line-height: 1.3;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu li h3 {
  color: #333;
  font-size: 22px;
  font-weight: 400;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu li a {
  color: #1b3043;
  font-size: 15px;
  font-weight: 400;
  padding: 2px 12px;
}
.breadcrumb {
  font-family: 'Cabin', sans-serif;
}
.breadcrumbs.breadcrumbs-light {
  background: #fbf9f5;
  padding: 14px 0 2px;
  border-bottom: solid 1px #a9bd38;
}
.breadcrumbs.breadcrumbs-light span {
  font-size: 2.4rem;
  color: #1b3043;
  font-weight: 300;
  margin-top: 8px;
}
.newsletter {
  color: #f3a843;
}
.newsletter:hover {
	color: #f6c27b;
}
.header-v8 .navbar-collapse {
  background: none; 
  border-color: #eee;
  border-bottom: none;
}

/* Packages Mega Menu */
.blog-thumb .blog-thumb-desc h3 {
	margin: 10px 0 0 0;
}

/*//////////
/// Forms
//////////*/

.form-control  {
  font-size: 16px;
  font-family: Raleway, 'sans-serif';
  background: #fbf9f5;
}
.formHide {
  display: none;
}
.has-feedback .form-control {
  padding-right: 28px;
}
.has-success .form-control {
  border-color: #a9bd38;
}
.has-error .form-control {
  border-color: #e76325;
}
.has-error .help-block {
  color: #e76325;
}
.btn[disabled] {
  color: #ffffff;
  background-color: #2851a3;
  border-color: #006b6b;
  opacity: 0.65;
}

/*//////////
/// Sub Navigation
//////////*/

.subnav a {
	font-size: 1.6rem;
	/*text-decoration: underline;*/
}
.subnav a:hover {
	/*text-decoration: none;*/
}

/*//////////
/// Pub Cluster Navigation
//////////*/

.blog-thumb .blog-thumb-desc h3 a {
  color: #2851a3;
}
.blog-thumb .blog-thumb-info li a {
  color: #333;
}

/*//////////
/// Category Box
//////////*/

.categorybox-image {
  position: relative;
}
.categorybox-link {
  position: absolute;
  bottom: 0;
  background-color: rgba(27,48,67,.7);
  color: #ffffff;
  padding: 6px 9pt 6px 8px;
}
.categorybox .advert {
  padding: 2px;
  color: #343333;
  font-size: .9rem;
}
.categorybox-image a:hover{
  color: #cccccc;
}
.categorybox img {
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
}
.categorybox h5 {
  margin-top:0;
  padding: 14px;
  color: #333;
  font-family: 'Cabin', sans-serif;
  border: 1px solid #e5e5e5;
  background: #f9f9f9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/*//////////
/// Category Pod Links
//////////*/

.category-pod-links {
  margin: 20px 20px 0;
  padding: 20px;
  float: left;
  background-color: #eee;
  font-weight: bold;
  font-size: 1.65rem;
}

/*//////////
/// Image Carousel
//////////*/

.carousel {
	border-top: 6px solid #f3a843;
	border-bottom: 14px solid #a9bd38;
}
.carousel-credit {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  color: #333333;
  background-color: rgba(255,255,255,.75);
  padding: 4px;
  width: 100%;
  text-align: center;
}
.carousel-credit .pinit {
  margin-right: 14px;
}

/*//////////
/// Image Hover Links
//////////*/

.hover-container {
  position: relative;
}

.hover-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.hover-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.hover-container:hover .hover-image {
  opacity: 0.3;
}

.hover-container:hover .hover-middle {
  opacity: 1;
}

.hover-text {
  background-color: #a9bd38;
  color: white;
  font-size: 12px;
  padding: 12px 28px;
}

/*//////////
/// Content
//////////*/

.panel-body .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\e114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
.panel-body .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080";    /* adjust as needed, taken from bootstrap.css */
}

.content-leader h2 {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.6rem;
  color: #222; 
  margin-bottom: 50px;
 }
.content-leader h3 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
  color: #222;
}
.content-bullets h4 {
  color: #222;
  font-weight: 700;
  margin: 50px 0 20px;  
}
.static-content h3 {
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 3.6rem;
	color: #222;
}
.static-content h4 li {
  margin-bottom: 20px;
}
.static-content p {
	font-size: 1.8rem;
	color: #333;
	font-family: 'Cabin', sans-serif;
}
.static-content li {
  font-size: 1.8rem;
  color: #333;
  font-family: 'Cabin', sans-serif;
}

.static-content table {
  margin: 10px 10px 20px;
  font-family: 'Cabin', sans-serif;
  font-size: 1.5rem;
}
.static-content tr:nth-child(even) {background: #f9f9f9}
.static-content th: {
  color: #222222;
  font-weight: bold;
}
.static-content td {
  padding: 4px;
  color: #808895;
}

/*//////////
/// Advertising
//////////*/

.advert {
	font-family: 'Raleway', sans-serif;
	color: #333333;
}
.listing-grid p {
  font-family: 'Raleway', sans-serif;
  clear: both;
}
.listing-grid p a {
  color: #333333;
}
.listing-grid p a:hover {
  text-decoration: none;
}
.listing-grid h3 {
  margin-top: 0;
  line-height: inherit;
}
.listing-grid h3 a {
	text-decoration: underline;
	color: #2851a3;
}
.listing-grid h3 a:hover {
  text-decoration: none;
  color: #1b3043;
}
.listing-grid h4 {
  margin-bottom: 0;
}
.listing-grid h4 a {
  text-decoration: underline;
  color: #2851a3;
}
.listing-grid h4 a:hover {
  text-decoration: none;
  color: #1b3043;
}
.listing-grid .listing-grid-info {
    padding-left: 0;
    list-style: none;
}
.listing-grid .listing-grid-info {
  margin-bottom: 0;
}
.listing-grid-info li a {
  color: #2851a3;
}
.listing-grid .listing-grid-info li {
    color: #888;
    padding: 0 2px;
    font-size: 12px;
    display: inline-block;
    float: left;
}
.listing-grid .listing-grid-info li a {
  color: #2851a3;
}
.listing-grid .listing-grid-info li a:hover {
  text-decoration: none;
}
.listing-grid .star-ratings {
  unicode-bidi: bidi-override;
  color: #c5c5c5;
  margin: -2px auto 0;
  position: relative;
  padding: 0;
}
.listing-grid .star-ratings-top {
  color: #f3a843;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  overflow: hidden;
}
.listing-grid .star-ratings-bottom {
  padding: 0;
  display: block;
  z-index: 0;
}
.r-more a {
	text-decoration: underline;
}
.r-more a:hover {
	text-decoration: none;
	border-bottom: none;
}
.listing-grid a.r-more {
  color: #2851a3;
  font-size: 1.4rem;
  font-style: normal;
  border-bottom: 1px solid #2851a3;
}
.listing-grid .claimbiz {
  font-family: Raleway, 'sans-serif';
  font-weight: 400;
}

/*/////////
/// Landing Content
//////////*/
.landingContent {
  background: rgba(243, 168, 67, .7);
}
.landingContentPreview {
  font-size: 2rem;
  text-align: center;
}
.landingContentCTA {
  padding-left: 1rem;
  background: #FFCC00;
  border-radius: 4px;
}
.landingContentWell {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #FFCC00;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

/*/////////
/// Webcams
//////////*/

.webcams .masonry-box .masonry-box-in {
  float: left;
  padding: 0 6px;
  margin-bottom: 14px;
}
.webcams h3 {
  margin: 2px 20px 30px;
}
.webcams h4 {
  margin: 0;
  font-size: 1.4rem;
}
.webcams h4 a {
  color: #ffffff;
}
.webcams p {
  margin: 2px 0;
}
.webcams .blog-grid img {
  margin: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.webcam-image {
  position: relative;
}
.webcam-title {
  position: absolute;
  top: 0;
  background-color: rgba(27,48,67,.7);
  color: #ffffff;
  padding: 6px 9pt 6px 8px;
  width: 100%;
}
#webcam-map {
  height: 300px;
  width: 100%;
}
.camsense-bg {
  width: 100%;
  background-color: #a9bd38;
}
.camleader {
  margin: 20px 20px 30px;
}

/*/////////
/// Packages
////////*/

.packages h4 {
  margin: 0;
  padding: 8px 2px;
}
.packages h3 {
  margin: 2px 20px 30px;
}
.packages p {
  margin: 2px 0;
  padding: 8px 2px 50px;
  border-bottom: 2px solid #a9bd38;
}
.packages .blog-grid img {
  margin: 0;
}
.package-image {
  position: relative;
}
.package-title {
  position: absolute;
  top: 0;
  background-color: rgba(27,48,67,.7);
  padding: 6px 9pt 6px 8px;
  width: 100%;
}
.package-price {
  position: absolute;
  bottom: 0;
  background-color: rgba(169,189,56,.7);
  color: #ffffff;
  padding: 6px 9pt 6px 8px;
}
.package-price span {
  color: #ffffff;
  font-size: .8em;
  font-weight: 400;
}
.package-highlight h3 {
  color: #ffffff;
  font-size: 4.6rem;
  font-weight: 600;
}
.package-highlight p {
  font-family: 'Raleway', sans-serif;
  line-height: 1.2rem;
}
.funny-boxes-at-lime {
  background: #a9bd38;
}
.package-product p {
  font-family: 'Raleway', sans-serif;
  font-size: 1.3rem;
}
#sendInquiryMessage {
  font-family: 'Raleway', sans-serif;
  color: #a9bd38;
  font-size: 2.2rem;
  font-weight: 500;
}
#sendInquiryMessage span {
  color: #a9bd38;
}
#sendInquiryMessage p {
  color: #222222;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Cabin', sans-serif;
}
#sendInquiryMessage h5 {
  font-family: 'Cabin', sans-serif;
  color: #f3a843;
  font-size: 3.2rem;
  margin-top: 50px;
  font-weight: 700;
}
/*#package-contact.affix {
  position: fixed;
  top: 20;
  width: 100%
}*/

/*/////////
/// Cabins CTA
////////*/

.callout-dark {
  margin: 40px 0;
  padding-top: 16px;
  color: #333;
  background-color: #e5e5e5;
}

.callout-dark h4 {
  font-size: 2.3rem;
  margin-top: 14px;
}

.callout-dark h1,
h2,
h3,
h4 {
  font-weight: 300;
  line-height: 1.4;
}

.callout .right {
  float: right;
}

.callout-dark p {
  color: #B1B1B1;
  font-size: 17px;
}

.callout-bubble {
  padding: 30px;
  color: #fff;
  background-color: #A94545;
}

.callout-bubble h1,
h2,
h3,
h4 {
  font-weight: 300;
  line-height: 1.4;
}

.callout-block {
  background-color: #F5F3F4;
  border-left: 5px solid #a94545;
  border-right: 5px solid #a94545;
  padding: 15px;
}

.callout-block h1,
.callout-block h2,
.callout-block h3,
.callout-block h4 {
  font-weight: 300;
  line-height: 1.4;
}

/*/////////
/// Maps
////////*/

#page-map {
  height: 400px;
  width: 100%;
}

/*//////////
/// SEO - Anchor
//////////*/

.anchor {
  padding: 30px 0 12px;
  background: #a9bd38;
  font-size: 1rem;
}
.anchor p {
  font-family: 'Raleway',sans-serif;
}
.anchor p strong {
  font-family: 'Roboto Slab',sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #1b3043;
}
.anchor strong a {
  color: #1b3043;
}
.anchor strong a:hover {
  color: #000;
}

/*//////////
/// Footer
//////////*/

/* Main Footer */
.footer-v8 .footer .column-one a:hover {
	color: #2851a3;
}
.footer-v8 .footer .tags-v4 a:hover {
	border-color: #2851a3;
	background-color: #2851a3;
}
.footer-v8 .footer .footer-lists li a:hover {
	color: #2851a3;
}
.footer-v8 .footer .latest-news h3 a:hover {
	color: #2851a3;
}
.footer-v8 .footer .input-group-btn .input-btn {
	background: #2851a3;
}
.footer-v8 .footer .social-icon-list li i:hover {
	background: #2851a3;
	border-color: #2851a3;
}
.footer-v8 .footer {
	background: #1b3043;
  border-top: 4px solid #a9bd38;
  padding-top: 60px;
} 
.footer-v8 .footer p {
  font-size: 13px;
  font-weight: 200;
  font-family: 'Raleway', sans-serif;
}
.footer-v8 .footer a {
  color: #a9bd38;
}
.footer-v8 .footer a:hover {
  color: #FFFFFF;
}
.footer-v8 .footer-row-one {
  background: url(/assets/img/trees.svg) 80% 100% no-repeat;
  background-size: 11em;
  border-bottom: 1px solid #a9bd38;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.footer-v8 .footer span {
  color: #a9bd38;
}
.footer-v8 .footer .direct span {
  color: #a9bd38;
  font-size: 2.8rem;
}
.footer-v8 .footer h2 {
	color: #f3a843;
}
.footer-v8 .footer .form-control {
	border-top-left-radius: 5px !important; 
	border-bottom-left-radius: 5px !important; 
}
.footer-v8 .footer .input-group {
	margin-right: 15px;
}

/*Super Footer*/
.footer-v8 .copyright {
	color: #ffffff;
  background: #12212e;
  padding: 40px 40px 120px;
  text-align: center;
}
.footer-v8 .copyright a {
  color: #a9bd38;
}
.footer-v8 .copyright a:hover {
  color: #ffffff;
  text-decoration: none;
}
.footer-v8 .copyright h1 {
  color: #ffffff;
  font-family: 'Raleway',sans-serif;
  font-weight: 100;
  font-size: 1.4rem;
  line-height: 4px;
  margin-top: 10px;
}
.footer-v8 .copyright .terms-menu li {
  font-family: 'Roboto Slab', sans-serif;
  color: #ffffff;
  border-left: 1px solid #ffffff;
}
.footer-v8 .copyright .terms-menu li a {
  color: #a9bd38; 
}
.footer-v8 .footcrumb {
  font-family: 'Raleway',sans-serif;
  font-weight: 100;
}

/*//////////
/// SEO H1 & Breadcrumb
/////////*/

#h1 {
  position: absolute;
  text-align: center;
  bottom: 60px;
  font-size: 1.4rem;
  color: #ffffff;
  width: 100%;
}
.microdataBread {
  color: #ffffff;
  position: absolute;
  text-align: center;
  bottom: 58px;
  width: 100%;
}
.microdataBread div {
  display: inline-block;
}
.microdataBread a {
  color: #a9bd38;
}
.microdataBread a:hover {
  color: #ffffff;
  text-decoration: none;
}

/* Top Control */
#topcontrol {
  background: #2851a3;
}

/*//////////
/// Homepage
//////////*/

.interactive-slider-v2 {
  padding: 120px 0;
  z-index: 1; /* Puts opacity on background image */
}
.interactive-slider-v2.img-v4 {
  background: url(/assets/img/home/winter.jpg) center center no-repeat;
  background-size: cover;
  width: 100%;
  border-bottom: 4px solid #a9bd38 ;
  position: relative;
  min-height: 480px;
}
.interactive-slider-v2:after {
  background: none;
}
.home {
  padding: 20px;
  background-color: rgba(53, 62, 68, .7);
  width: 70%;
  border: 1px solid #fff;
}
.homeCategory h5 a {
  position: absolute;
  bottom: 0;
  background-color: rgba(53, 62, 68, .9);
  font-size: 1.5rem;
  color: #ffffff;
  padding: 10px;
  width: 100%;
}
.homeCategory .advert {
  position: absolute;
  top: 0;
  right: 0;
  color: #333;
  background-color: rgba(255,255,255,.9);
  padding: 4px;
  margin: 0;
  font-size: .9rem;
}
.homeCategory h5 a:hover {
  color: #ffffff;
}
.home h1 {
  font-size: 4.4rem;
  line-height: 4.4rem;
  margin-bottom: 2rem;
}
.home h2 {
  font-size: 2.4rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
  color: #ffffff;
}
.home h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: #f3a843;
  padding: 10px;
  background-color: rgba(0,0,0,.55);
}
.home .advert {
  color: #e5e5e5;
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
}
.home .btn-group {
  margin: 0 6px;
}
.home .dropdown-menu {
  font-size: 13px;
  background: #fbf9f5;
}
.home .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
  color: #2851a3;
  text-decoration: none;
  background-color: #ffffff;
}
.home .last a {
  color: #2851a3;
}
.home small {
  font-size: .8rem;
}

/* Spotlights */
.spotlights-bottom h3 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #1b3043;
  margin-left: 20px;
}
.spotlights-pad {
  margin-left: 20px;
}
.spotlights .masonry-box .masonry-box-in {
  float: left;
  padding: 0 6px;
  margin-bottom: 14px;
}
.spotlights h3 {
  margin: 2px 20px 30px;
  /*clear: left;*/
}
.spotlights h4 {
  margin: 0;
  font-size: 1.4rem;
}
.spotlights h4 a {
  color: #ffffff;
  text-decoration: none;
}
.spotlights h4 a:hover {
  color: #fafafa;
}
.spotlights p {
  margin: 2px 0;
}
.spotlights .blog-grid img {
  margin: 0;
  width: 100%;
}
.spotlight-image {
  position: relative;
}
.spotlight-title {
  position: absolute;
  top: 0;
  background-color: rgba(27,48,67,.7);
  color: #ffffff;
  padding: 6px 9pt 6px 8px;
  width: 100%;
}
.spotlight-price {
  position: absolute;
  bottom: 0;
  background-color: rgba(27,48,67,.7);
  color: #ffffff;
  padding: 6px 9pt 6px 8px;
}
.spotlight-price span {
  color: #cccccc;
  font-size: .8em;
  font-weight: 400;
}
.spotlight-image .advert {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #333;
  background-color: rgba(255,255,255,.9);
  padding: 2px;
  margin: 0;
  font-size: .8rem;
}
.currency {
  font-size: 60% !important;
  top: -.4em;
  position: relative;
}
.social iframe {
  /*vertical-align: middle;*/
  margin-top: -5px;
}

.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  font-family: Raleway, 'sans-serif';
  font-weight: 100;
  font-size: 50px;
  color: #fff;
  line-height: 120px;
  text-align: center;
  background: #a9bd38;
}

/*/////////
/// Spacers
/////////*/

.m-t-5 {margin-top: 5px;}
.m-t-10 {margin-top: 10px;}
.m-t-20 {margin-top: 20px;}
.m-t-30 {margin-top: 30px;}
.m-t-40 {margin-top: 40px;}
.m-t-50 {margin-top: 50px;}
.m-r-5 {margin-right: 5px;}
.m-r-10 {margin-right: 10px;}
.m-r-20 {margin-right: 20px;}
.m-r-30 {margin-right: 30px;}
.m-r-40 {margin-right: 40px;}
.m-r-50 {margin-right: 50px;}
.m-b-5 {margin-bottom: 5px;}
.m-b-10 {margin-bottom: 10px;}
.m-b-20 {margin-bottom: 20px;}
.m-b-30 {margin-bottom: 30px;}
.m-b-40 {margin-bottom: 40px;}
.m-b-50 {margin-bottom: 50px;}
.m-x-10 {margin-left: 10px; margin-right: 10px;}
.m-x-20 {margin-left: 20px; margin-right: 20px;}
.m-x-50 {margin-left: 50px; margin-right: 50px;}
.m-y-10 {margin-top: 10px; margin-bottom: 10px;}
.m-y-20 {margin-top: 20px; margin-bottom: 20px;}
.m-y-50 {margin-top: 50px; margin-bottom: 50px;}
.m-a-5 {margin: 5px;}
.m-a-10 {margin: 10px;}
.m-a-20 {margin: 20px;}
.m-a-50 {margin: 50px;}
.p-t-5 {padding-top: 5px;}
.p-t-10 {padding-top: 10px;}
.p-t-20 {padding-top: 20px;}
.p-t-50 {padding-top: 50px;}
.p-a-5 {padding: 5px;}
.p-a-10 {padding: 10px;}
.p-a-20 {padding: 20px;}
.p-a-50 {padding: 50px;}
