/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.9.2
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/

/*Thematic Break
------------------------------------*/
hr {
	margin: 30px 0;
	
}

hr.hr-xs {
	margin: 10px 0;
}

hr.hr-md {
	margin: 20px 0;
}

hr.hr-lg {
	margin: 40px 0;
}

/*Headings
------------------------------------*/
h1 {
	font-size: 28px;
	line-height: 35px;
}
h2 {
	font-size: 24px;
	line-height: 33px;
}
h3 {
	font-size: 20px;
	line-height: 27px;
}
h4 {
	line-height: 25px;
}
h5 {
	line-height: 20px;
}
h6 {
	line-height: 18px;
}

.font-open-sans {
	font-family: "Open Sans", Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	color: #555;
	margin-top: 5px;
	text-shadow: none;
	font-weight: normal;
	font-family: "Open Sans", Arial, sans-serif;
}

h1 i, h2 i, h3 i, h4 i, h5 i, h6 i {
	margin-right: 5px;
}

/*Title v4*/
.title-v4 {
	font-size: 18px;
	margin: 0 0 20px;
	font-weight: bold;
	position: relative;
	text-transform: uppercase;
	border-bottom: 3px solid #333;
}

/*Heading Sizes
------------------------------------*/
h2.heading-md {
	font-size: 20px;
	line-height: 24px;
}

h2.heading-sm,
h3.heading-md {
	font-size: 18px;
	line-height: 24px;
}

h3.heading-md {
	line-height: 22px;
}

h3.heading-sm {
	font-size: 16px;
	line-height: 20px;
}

h2.heading-xs {
	font-size: 16px;
	line-height: 22px;
}

h3.heading-xs {
	font-size: 14px;
	margin-bottom: 0;
}

/*Tables
------------------------------------*/
/*Basic Tables*/
.table thead > tr > th {
	border-bottom: none;
}

@media (max-width: 768px) {
	.table th.hidden-sm,
	.table td.hidden-sm {
		display: none !important;
	}
}

/*Forms
------------------------------------*/
.form-control {
	box-shadow: none;
	border-radius: 0;
}

.input-group-addon {
	border-radius: 0;
}

.form-control:focus {
	border-color: #bbb;
	box-shadow: 0 0 2px #c9c9c9;
}

/*Form Spacing*/
.form-spacing .form-control {
	margin-bottom: 15px;
}

/*Form Icons*/
.input-group-addon {
	color: #b3b3b3;
	font-size: 14px;
	background: #fff;
}

/*Navigation
------------------------------------*/
.dropdown-menu,
.navbar-toggle {
	border-radius: 0;
}

/*Button Styles
------------------------------------*/
.btn {
	box-shadow: none;
	border-radius: 2px;
}
.btn-u {
	border: 0;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
	font-weight: 400;
	padding: 6px 13px;
	position: relative;
	background: #72c02c;
	white-space: nowrap;
	display: inline-block;
	text-decoration: none;
}
.btn-u:hover {
	color: #fff;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}

.btn-u.btn-block {
	text-align: center;
}

.btn-u-sm,
a.btn-u-sm {
	padding: 3px 12px;
}

.btn-u-md,
a.btn-u-md {
	padding: 10px 20px;
}

.btn-u-lg,
a.btn-u-lg {
	font-size: 18px;
	padding: 10px 25px;
}

.btn-u-xs,
a.btn-u-xs {
	font-size: 12px;
	padding: 2px 12px;
	line-height: 18px;
}

/*Button Groups*/
.btn-group .dropdown-menu > li > a {
	padding: 3px 13px;
}

.btn-group > .btn-u,
.btn-group-vertical > .btn-u {
	float: left;
	position: relative;
}

.btn-group > .btn-u:first-child {
	margin-left: 0;
}

/*For FF Only*/
@-moz-document url-prefix() {
	.footer-subsribe .btn-u {
		padding-bottom: 4px;
	}
}
@media (max-width: 768px) {
	@-moz-document url-prefix() {
		.btn-u {
			padding-bottom: 6px;
		}
	}
}

/*Buttons Color*/
.btn-u:hover,
.btn-u:focus,
.btn-u:active,
.btn-u.active,
.open .dropdown-toggle.btn-u {
	background: #5fb611;
}
.btn-u-split.dropdown-toggle {
	border-left: solid 1px #5fb611;
}

/*Bordered Buttons*/
.btn-u.btn-brd {
	color: #555;
	background: none;
	padding: 5px 13px;
	border: solid 1px transparent;
}
.btn-u.btn-brd-width-2 {
	padding: 7px 18px;
	border-width: 2px;
}
.btn-u.btn-brd:hover {
	background: none;
}
.btn-u.btn-brd:focus {
	background: none;
}
.btn-u.btn-brd.btn-brd-hover:hover {
	color: #fff !important;
}

.btn-u.btn-brd {
	border-color: #72c02c;
}
.btn-u.btn-brd:hover {
	color: #5fb611;
	border-color: #5fb611;
}
.btn-u.btn-brd.btn-brd-hover:hover {
	background: #5fb611;
}

/*Typography
------------------------------------*/
.text-justify p { text-align: justify;}
.text-transform-uppercase { text-transform: uppercase;}
.text-transform-normal { text-transform: inherit !important;}

.font-bold { font-weight: 600;}
.font-light { font-weight: 200;}
.font-normal { font-weight: 400 !important;}

/*Blockquote*/
blockquote {
	padding: 5px 15px;
	border-left-width: 2px;
}

blockquote p {
	font-size: 14px;
	font-weight: 400;
}

blockquote h1,
blockquote h2,
blockquote span {
	font-size: 18px;
	margin: 0 0 8px;
	line-height: 24px;
}

/*Blockquote Styles*/
blockquote.bq-text-lg p,
blockquote.bq-text-lg small {
	text-transform: uppercase;
}

blockquote.bq-text-lg p {
	font-size: 22px;
	font-weight: 300;
	line-height: 32px;
}

blockquote.text-right,
blockquote.hero.text-right {
	border-left: none;
	border-right: 2px solid #eee;
}

blockquote.hero.text-right,
blockquote.hero.text-right:hover {
	border-color: #555;
}

blockquote:hover,
blockquote.text-right:hover {
	border-color: #72c02c;
	transition: all 0.4s ease-in-out;
}

blockquote.bq-dark,
blockquote.bq-dark:hover {
	border-color: #585f69;
}

blockquote.bq-green {
	border-color: #72c02c;
}

/*Blockquote Hero Styles*/
blockquote.hero {
	border: none;
	padding: 18px;
	font-size: 16px;
	background: #f3f3f3;
	border-left: solid 2px #666;
}

blockquote.hero:hover {
	background: #eee;
	border-left-color: #666;
}

blockquote.hero.hero-dark,
blockquote.hero.hero-default {
	border: none;
}

blockquote.hero.hero-dark {
	background: #444;
}

blockquote.hero.hero-dark:hover {
	background: #555;
}

blockquote.hero.hero-default {
	background: #72c02c;
}

blockquote.hero.hero-default:hover {
	background: #5fb611;
}

blockquote.hero.hero-dark p,
blockquote.hero.hero-dark h2,
blockquote.hero.hero-dark small,
blockquote.hero.hero-default p,
blockquote.hero.hero-default h2,
blockquote.hero.hero-default small {
	color: #fff;
	font-weight: 200;
}

/*Alerts
------------------------------------*/
.alert {
	border-radius: 0;
}

/*Thumbnails
------------------------------------*/
.thumbnail {
	border-radius: 0;
}

/*Panels (Portlets)
------------------------------------*/
.panel,
.panel-heading,
.panel-group .panel {
	border-radius: 0;
}

.panel-heading {
	color: #fff;
	padding: 5px 15px;
}

/*Panel Table*/
.panel .table {
	margin-bottom: 0;
}

/*Panel Unify*/
.panel-u {
	border-color: #72c02c;
}

.panel-u > .panel-heading {
	background: #72c02c;
}

/*Media (Audio/Videos and Images)
------------------------------------*/
/*Images*/
img.img-bordered {
	padding: 3px !important;
	border: solid 1px #eee !important;
}

img.img-circle {
	border-radius: 50% !important;
}

img.image-sm {
	width: 50px;
	height: 50px;
}

img.image-md {
	width: 100px;
	height: 100px;
}

/*Responsive Video*/
.responsive-video {
 height: 0;
 padding-top: 1px;
 position: relative;
 padding-bottom: 56.25%; /*16:9*/
}

.responsive-video iframe {
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 position: absolute;
}

/*Column Sizes
------------------------------------*/
/*Remove the Gutter Padding from Columns*/
.no-gutter > [class*='col-'] {
	padding-right: 0;
	padding-left: 0;
}

.no-gutter.no-gutter-boxed {
	padding-right: 15px;
	padding-left: 15px;
}

/*Heights
------------------------------------*/
.height-100 { min-height: 100px;}
.height-150 { min-height: 150px;}
.height-200 { min-height: 200px;}
.height-250 { min-height: 250px;}
.height-300 { min-height: 300px;}
.height-350 { min-height: 350px;}
.height-400 { min-height: 400px;}
.height-450 { min-height: 450px;}
.height-500 { min-height: 500px !important;}

/*Spaces
------------------------------------*/
.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0;
}

.no-top-space {
	margin-top: 0 !important;
	padding-top: 0 !important;
}

.no-bottom-space {
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.content-xs {
	padding-top: 20px;
	padding-bottom: 20px;
}

.content {
	padding-top: 40px;
	padding-bottom: 40px;
}

.content-sm {
	padding-top: 40px;
	padding-bottom: 40px;
}

.content-md {
	padding-top: 80px;
	padding-bottom: 80px;
}

.content-lg {
	padding-top: 100px;
	padding-bottom: 100px;
}

.content-xlg {
	padding-top: 150px;
	padding-bottom: 150px;
}

.space-md-hor {
	padding-left: 40px;
	padding-right: 40px;
}

.space-lg-hor {
	padding-left: 60px;
	padding-right: 60px;
}

.space-xlg-hor {
	padding-left: 100px;
	padding-right: 100px;
}

.margin-bottom-5,
.margin-bottom-10,
.margin-bottom-15,
.margin-bottom-20,
.margin-bottom-25,
.margin-bottom-30,
.margin-bottom-35,
.margin-bottom-40,
.margin-bottom-45,
.margin-bottom-50,
.margin-bottom-55,
.margin-bottom-60,
.margin-bottom-100 {
	 clear:both;
}
.margin-bottom-5  { margin-bottom:5px;}
.margin-bottom-10 { margin-bottom:10px;}
.margin-bottom-15 { margin-bottom:15px;}
.margin-bottom-20 { margin-bottom:20px;}
.margin-bottom-25 { margin-bottom:25px;}
.margin-bottom-30 { margin-bottom:30px;}
.margin-bottom-35 { margin-bottom:35px;}
.margin-bottom-40 { margin-bottom:40px;}
.margin-bottom-45 { margin-bottom:45px;}
.margin-bottom-50 { margin-bottom:50px;}
.margin-bottom-55 { margin-bottom:55px;}
.margin-bottom-60 { margin-bottom:60px;}
.margin-bottom-70 { margin-bottom:70px;}
.margin-bottom-80 { margin-bottom:80px;}
.margin-bottom-90 { margin-bottom:90px;}
.margin-bottom-100 { margin-bottom:100px;}

@media (max-width: 768px) {
	.sm-margin-bottom-10 {
		margin-bottom: 10px;
	}
	.sm-margin-bottom-20 {
		margin-bottom: 20px;
	}
	.sm-margin-bottom-30 {
		margin-bottom: 30px;
	}
	.sm-margin-bottom-40 {
		margin-bottom: 40px;
	}
	.sm-margin-bottom-50 {
		margin-bottom: 50px;
	}
	.sm-margin-bottom-60 {
		margin-bottom: 60px;
	}
}

@media (max-width: 992px) {
	.md-margin-bottom-10 {
		margin-bottom: 10px;
	}
	.md-margin-bottom-20 {
		margin-bottom: 20px;
	}
	.md-margin-bottom-30 {
		margin-bottom: 30px;
	}
	.md-margin-bottom-40 {
		margin-bottom: 40px;
	}
	.md-margin-bottom-50 {
		margin-bottom: 50px;
	}
	.md-margin-bottom-60 {
		margin-bottom: 60px;
	}
}

/*Other Spaces*/
.margin-top-20 { margin-top: 20px;}
.margin-left-5 { margin-left: 5px;}
.margin-left-10 { margin-left: 10px;}
.margin-right-5 { margin-right: 5px;}
.margin-right-10 { margin-right: 10px;}
.margin-right-20 { margin-right: 20px;}

.padding-sm { padding: 40px 40px;}
.padding-top-5 { padding-top: 5px;}
.padding-left-5 { padding-left: 5px;}

/*Rounded and Circle Classes
------------------------------------*/
.no-rounded { border-radius: 0 !important;}
.rounded { border-radius: 4px !important;}
.rounded-x { border-radius: 50% !important;}
.rounded-2x { border-radius: 10px !important;}
.rounded-3x { border-radius: 15px !important;}
.rounded-4x { border-radius: 20px !important;}
.rounded-sm { border-radius: 2px !important;}
.rounded-md { border-radius: 3px !important;}
.rounded-top { border-radius: 4px 4px 0 0 !important;}
.rounded-left { border-radius: 4px 0 0 4px !important;}
.rounded-right { border-radius: 0 4px 4px 0 !important;}
.rounded-bottom { border-radius: 0 0 4px 4px !important;}

/*Others
------------------------------------*/
.overflow-h { overflow: hidden;}
.overflow-a { overflow: auto;}
.overflow-hidden { overflow: hidden;}

.clear-both { clear: both;}

/*Display*/
.dp-none { display: none;}
.dp-block { display: block;}
.dp-table { display: table;}
.dp-inline-block { display: inline-block;}
.dp-table-cell {
	display: table-cell;
	vertical-align: middle;
}

/*Font Size*/
.text-light { font-weight: 200; }
.text-normal { font-weight: 400; }
.text-bold { font-weight: 600 !important; }

/*Full Width*/
.full-width { width: 100%; }

/*Equal Height Columns*/
@media (max-width: 767px) {
	.equal-height-column {
		height: auto !important;
	}
}

/*Image Classes*/
.img-width-60 { width: 60px;}
.img-width-200 { width: 200px;}
.lft-img-margin { margin:0 20px 5px 0;}
.rgt-img-margin { margin:0 0 5px 10px;}

img.img-center,
.img-center img {
	margin-left: auto;
	margin-right: auto;
}

/*CSS3 Hover Effects*/
.hover-effect {
 transition: all 0.4s ease-in-out;
}

.hover-effect-kenburn {
 left:10px;
 margin-left:-10px;
 position:relative;
 transition: all 0.8s ease-in-out;
}
.hover-effect-kenburn:hover {
 transform: scale(2) rotate(5deg);
}

/*Scroll Icon*/
.scroll-btn-icon,
.scroll-btn-icon:before {
	position: absolute;
	left: 50%;
}
.scroll-btn-icon {
	width: 30px;
	z-index: 2;
	height: 50px;
	bottom: 30px;
	margin-left: -25px;
	border-radius: 25px;
	box-shadow: inset 0 0 0 1px rgba(255,255,255,0.5);
}
.scroll-btn-icon-dark {
	box-shadow: inset 0 0 0 1px rgba(0,0,0,0.5);
}
.scroll-btn-icon:before {
	top: 8px;
	width: 8px;
	height: 8px;
	content: '';
	background: #fff;
	margin-left: -4px;
	border-radius: 4px;
	animation-name: scroll;
	animation-duration: 1.5s;
	-webkit-animation-name: scroll;
	-webkit-animation-duration: 1.5s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}
.scroll-btn-icon-dark:before {
	background: #333;
}
@-webkit-keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(36px);
		-webkit-transform: translateY(36px);
	}
}
@keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(36px);
		-webkit-transform: translateY(36px);
	}
}
